#root .alert-talk-section6 {
  position: relative;
  padding: 180px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .alert-talk-section6 > .gradient {
  width: 100%;
  position: absolute;
  top: 0;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper {
  z-index: 1;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper > .text-main {
  font-size: 48px;
  font-weight: 700;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper > .text-sub {
  font-size: 24px;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  position: relative;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper > .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 24px;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper > .img-box > .img-btn {
  padding: 10px 41px;
  color: white;
  border-radius: 90px;
  background-color: #0BB8A8;
  font-size: 24px;
  z-index: 1;
  font-weight: 700;
}
#root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper > .line {
  position: absolute;
  bottom: 20px;
  right: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#root .alert-talk-section6 > .motion-wrapper {
  margin-top: 331px;
}
#root .alert-talk-section6 > .motion-wrapper > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 10px;
}
#root .alert-talk-section6 > .motion-wrapper > .text-wrapper > .main-text {
  font-size: 48px;
  font-weight: 700;
  color: #363840;
}
#root .alert-talk-section6 > .motion-wrapper > .text-wrapper > .sub-text {
  color: #363840;
  font-size: 24px;
}
#root .alert-talk-section6 > .motion-wrapper > .video-box {
  overflow: hidden;
}
#root .alert-talk-section6 > .motion-wrapper > .video-box video {
  width: 100%;
  height: 100%;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper {
    row-gap: 10px;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper > .text-main {
    font-size: 34px;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper > .text-sub {
    font-size: 24px;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 66px;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper > .img-box {
    row-gap: 28px;
  }
  #root .alert-talk-section6 > .motion-wrapper {
    margin-top: 160px;
  }
  #root .alert-talk-section6 > .motion-wrapper > .text-wrapper > .main-text {
    font-size: 34px;
  }
  #root .alert-talk-section6 > .motion-wrapper > .text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .alert-talk-section6 > .motion-wrapper > .video-box {
    width: 687px;
    height: 459px;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-section6 {
    padding: 132px 0px;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper {
    row-gap: 10px;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper > .text-main {
    font-size: 30px;
    white-space: pre-line;
    text-align: center;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .alert-talk-section6-text-wrapper > .text-sub {
    font-size: 20px;
    font-weight: 500;
    white-space: pre-line;
    text-align: center;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 66px;
  }
  #root .alert-talk-section6 > .alert-talk-section6-wrapper > .img-wrapper > .img-box {
    row-gap: 28px;
  }
  #root .alert-talk-section6 > .motion-wrapper > .text-wrapper {
    row-gap: 10px;
  }
  #root .alert-talk-section6 > .motion-wrapper > .text-wrapper > .main-text {
    font-size: 30px;
    white-space: pre-line;
    line-height: 42px;
  }
  #root .alert-talk-section6 > .motion-wrapper > .text-wrapper > .sub-text {
    font-size: 20px;
  }
}