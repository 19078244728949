#root .pos-type-v2 {
  width: 100%;
  height: 395px;
  background-color: var(--mint800);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}
#root .pos-type-v2 > img {
  position: absolute;
}
#root .pos-type-v2 > .left-img {
  left: 0;
  bottom: 0;
}
#root .pos-type-v2 > .right-img {
  right: 0;
  top: 0;
}
#root .pos-type-v2 .pos-type-v2-title {
  color: white;
  font-size: 50px;
  font-weight: 800;
}
#root .pos-type-v2 .pos-type-v2-sub {
  color: white;
  font-size: 18px;
  font-weight: bold;
  white-space: pre-line;
  line-height: 180%;
  margin-top: 8px;
  text-align: center;
}
#root .pos-type-v2 .download-btn {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 304px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  -webkit-column-gap: 3px;
     -moz-column-gap: 3px;
          column-gap: 3px;
}
#root .pos-type-v2 .download-btn .download-btn-title {
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 1439px) {
  #root .pos-type-v2 > .left-img {
    width: 280px;
  }
  #root .pos-type-v2 > .right-img {
    width: 280px;
  }
}
@media screen and (max-width: 767px) {
  #root .pos-type-v2 .pos-type-v2-title {
    font-size: 28px;
  }
  #root .pos-type-v2 .pos-type-v2-sub {
    font-size: 15px;
  }
  #root .pos-type-v2 .download-btn {
    margin-top: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 300px;
    height: 50px;
    background-color: white;
    border-radius: 5px;
    -webkit-column-gap: 3px;
       -moz-column-gap: 3px;
            column-gap: 3px;
  }
  #root .pos-type-v2 .download-btn .download-btn-title {
    font-size: 18px;
    font-weight: bold;
  }
  #root .pos-type-v2 > .left-img {
    width: 180px;
  }
  #root .pos-type-v2 > .right-img {
    width: 180px;
  }
}