@-webkit-keyframes scroll-spin {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes scroll-spin {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
#root .mouse-scroll {
  width: 53px;
  height: 78px;
  border-radius: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 51px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#root .mouse-scroll > .mouse-scroll-img-box {
  overflow: hidden;
}
#root .mouse-scroll > .mouse-scroll-img-box > .mouse-scroll-img {
  -webkit-animation: scroll-spin 1.5s ease-in-out infinite;
          animation: scroll-spin 1.5s ease-in-out infinite;
}
#root .mouse-scroll > .scroll-text {
  font-size: 10px;
  color: #F5F6F8;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 767px) {
  #root .mouse-scroll {
    bottom: 0px;
  }
}