#root .alert-talk-section8 {
  padding: 180px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #2D2E36;
}
#root .alert-talk-section8 > .alert-talk-section8-wrapper {
  width: 790px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 106px;
}
#root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper > .text {
  font-size: 44px;
  color: white;
  font-weight: 600;
}
#root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper > .sub-text {
  font-size: 24px;
  line-height: 34px;
  color: #949494;
  white-space: pre;
}
#root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box:nth-child(2) img {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-section8 {
    padding: 160px 0px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 100px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box {
    display: unset;
    text-align: center;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper {
    margin-top: 40px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper > .text {
    font-size: 34px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box:nth-child(2) img {
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-section8 {
    padding: 132px 0px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 104px;
    width: auto;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box {
    display: unset;
    text-align: center;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper {
    margin-top: 40px;
    row-gap: 0px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper > .text {
    font-size: 30px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box > .text-wrapper > .sub-text {
    font-size: 20px;
  }
  #root .alert-talk-section8 > .alert-talk-section8-wrapper > .img-box:nth-child(2) img {
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
  }
}