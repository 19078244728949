#root .menu-btn {
  width: 310px;
  height: 104px;
  border-radius: 10px;
  background-color: #F5F6F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .menu-btn > .arrow {
  margin-left: auto;
}
#root .menu-btn > .text-box {
  margin-left: 16px;
  text-align: left;
}
#root .menu-btn > .text-box > .title {
  color: #01A0A0;
  font-size: 20px;
  font-weight: 700;
}
#root .menu-btn > .text-box > .sub {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  white-space: pre-line;
  line-height: 150%;
  color: #363840;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .menu-btn > .text-box > .title {
    font-size: 20px;
  }
  #root .menu-btn > .text-box > .sub {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  #root .menu-btn {
    width: 100%;
    height: 87px;
  }
  #root .menu-btn > .text-box > .title {
    font-size: 18px;
  }
  #root .menu-btn > .text-box > .sub {
    font-size: 13px;
  }
}