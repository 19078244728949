#root .alert-talk-section9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 180px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: white;
}
#root .alert-talk-section9 > .title {
  color: #2D2E36;
  font-weight: 700;
  font-size: 48px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-section9 {
    padding: 160px 0px;
  }
  #root .alert-talk-section9 > .title {
    font-size: 34px;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-section9 {
    padding: 132px 0px;
  }
  #root .alert-talk-section9 > .title {
    white-space: pre-line;
    font-size: 30px;
    text-align: center;
    line-height: 42px;
  }
}