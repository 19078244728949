#root .alert-talk-page {
  position: relative;
  padding-top: 66px;
}
#root .alert-talk-page > .alert-talk-banner {
  width: 100%;
  height: 845px;
  position: relative;
}
#root .alert-talk-page > .alert-talk-banner video {
  width: 100%;
  height: 845px;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}
#root .alert-talk-page > .alert-section07 {
  width: 100%;
  height: 458px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .alert-talk-page > .alert-section07 > .text {
  color: white;
  line-height: 44.2px;
  font-size: 34px;
  font-weight: 700;
  white-space: pre-wrap;
  z-index: 1;
  padding: 0px 201px;
}
#root .alert-talk-page > .alert-section07 > .bg-img {
  width: 100%;
  height: 458px;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-page > .alert-section07 {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .alert-talk-page > .alert-section07 > .text {
    padding: 0;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-page > .alert-talk-banner {
    width: 100%;
    height: unset;
  }
  #root .alert-talk-page > .alert-talk-banner video {
    width: 100%;
    height: auto;
  }
  #root .alert-talk-page > .alert-section07 {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .alert-talk-page > .alert-section07 > .text {
    padding: 0;
    text-align: center;
  }
}