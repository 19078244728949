#root .main-section10-card:nth-child(2) img {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
#root .main-section10-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .main-section10-card > .content-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .main-section10-card > .content-text > .excel {
  width: 60px;
  height: 60px;
}
#root .main-section10-card > .content-text > .text {
  white-space: pre;
  color: white;
  font-size: 44px;
  font-weight: 600;
  line-height: 66px;
}
#root .main-section10-card > .content-text > .sub-text {
  font-size: 24px;
  font-weight: 400;
  color: white;
  white-space: pre;
}
@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section10-card {
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
  }
  #root .main-section10-card > .content-text > .text {
    font-size: 26px;
    line-height: 36.4px;
  }
  #root .main-section10-card > .content-text > .sub-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section10-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .main-section10-card img:nth-child(1) {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  #root .main-section10-card > .content-text {
    text-align: center;
    margin-bottom: 10px;
  }
  #root .main-section10-card > .content-text > .excel {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #root .main-section10-card > .content-text > .text {
    font-size: 24px;
    line-height: 36px;
  }
  #root .main-section10-card > .content-text > .sub-text {
    font-size: 20px;
  }
}