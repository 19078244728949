#root .alert-talk-section5 {
  padding: 180px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 50px;
}
#root .alert-talk-section5 > .alert-talk-section5-title {
  font-size: 48px;
  font-weight: 700;
}
#root .alert-talk-section5 > .upload-img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box {
  width: 451px;
  height: 429px;
  padding: 50px 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  row-gap: 48px;
  border-radius: 10px;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-text-wrapper > .upload-main-text {
  font-size: 32px;
  font-weight: 700;
  color: #2D2E36;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-text-wrapper > .upload-sub-text {
  color: #54585A;
  font-size: 24px;
  font-weight: 500;
  white-space: pre;
  line-height: 36px;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-img-box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-img-box > .skpos-logo {
  width: 154px;
  height: 154px;
  background-color: white;
  -webkit-box-shadow: 0 0 30px 30px rgba(5, 217, 174, 0.3490196078);
          box-shadow: 0 0 30px 30px rgba(5, 217, 174, 0.3490196078);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 12px;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-img-box > .skpos-logo > .text-logo {
  width: 93px;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box:nth-child(1) {
  background-color: #E6FBF7;
}
#root .alert-talk-section5 > .upload-img-wrapper > .upload-box:nth-child(2) {
  background-color: #CDF7EF;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-section5 > .alert-talk-section5-title {
    font-size: 34px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper {
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box {
    width: 336px;
    height: 320px;
    padding: 28px 28px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-text-wrapper > .upload-main-text {
    font-size: 32px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-text-wrapper > .upload-sub-text {
    font-size: 20px;
    line-height: 30px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-img-box > .skpos-logo {
    width: 114px;
    height: 114px;
    -webkit-box-shadow: 0 0 10px 10px rgba(5, 217, 174, 0.3490196078);
            box-shadow: 0 0 10px 10px rgba(5, 217, 174, 0.3490196078);
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-img-box > .skpos-logo > .text-logo {
    width: 72px;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-section5 {
    padding: 132px 0px;
  }
  #root .alert-talk-section5 > .alert-talk-section5-title {
    font-size: 30px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 20px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box {
    width: 320px;
    height: 320px;
    padding: 24px 24px;
    row-gap: 24px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-text-wrapper > .upload-main-text {
    font-size: 24px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-text-wrapper > .upload-sub-text {
    font-size: 20px;
    line-height: 30px;
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-img-box > .skpos-logo {
    width: 114px;
    height: 114px;
    -webkit-box-shadow: 0 0 10px 10px rgba(5, 217, 174, 0.3490196078);
            box-shadow: 0 0 10px 10px rgba(5, 217, 174, 0.3490196078);
  }
  #root .alert-talk-section5 > .upload-img-wrapper > .upload-box > .upload-img-box > .skpos-logo > .text-logo {
    width: 72px;
  }
}