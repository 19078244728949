#root .myinquiry-page {
  padding-top: 60px;
}
#root .myinquiry-page > .myinquiry-section {
  width: 874px;
  margin: 0 auto;
  padding: 53px 0;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper > .input-wrapper {
  width: 100%;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper > .input-wrapper > .text {
  font-size: 26px;
  font-weight: 700;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper {
  margin: 34px 0 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper > .search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 369px;
  height: 50px;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  padding: 0 13px;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper > .search-form .search-input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper > .search-form > .search-button {
  width: 23px;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper > .write-link {
  border: none;
  width: 155px;
  height: 50px;
  background-color: var(--mint800);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 16px;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper .inquiry-list-wrapper {
  width: 100%;
}
#root .myinquiry-page > .myinquiry-section > .myinquiry-wrapper .none-login {
  width: 100%;
  height: 144px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: var(--grey400);
  background-color: var(--grey100);
  border-radius: 5px;
}

@media screen and (max-width: 1365px) {
  #root .myinquiry-page > .myinquiry-section {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 53px 20px 100px 20px;
  }
}
@media screen and (max-width: 767px) {
  #root .myinquiry-page .myinquiry-section .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 16px;
  }
  #root .myinquiry-page .myinquiry-section .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper > .search-form {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .myinquiry-page .myinquiry-section .myinquiry-wrapper > .input-wrapper > .inquiry-search-wrapper > .write-link {
    width: 100%;
  }
}