#root .trend-middle-box {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 90px;
     -moz-column-gap: 90px;
          column-gap: 90px;
}
#root .trend-middle-box > .skpos-logo-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .trend-middle-box > .skpos-logo-wrapper > .top-line {
  position: absolute;
  top: -52px;
}
#root .trend-middle-box > .skpos-logo-wrapper > .bottom-line {
  bottom: -52px;
  right: -60px;
  position: absolute;
}
#root .trend-middle-box > .skpos-logo-wrapper > .skpos-logo {
  width: 210px;
  height: 210px;
  background-color: white;
  -webkit-box-shadow: 0 0 30px 30px rgba(5, 217, 174, 0.3490196078);
          box-shadow: 0 0 30px 30px rgba(5, 217, 174, 0.3490196078);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 12px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .trend-middle-box > .skpos-logo-wrapper > .top-line {
    top: -40px;
  }
  #root .trend-middle-box > .skpos-logo-wrapper > .bottom-line {
    bottom: -30px;
    right: -38px;
  }
  #root .trend-middle-box > .skpos-logo-wrapper > .skpos-logo {
    width: 132px;
    height: 132px;
    -webkit-box-shadow: 0 0 20px 20px rgba(5, 217, 174, 0.3490196078);
            box-shadow: 0 0 20px 20px rgba(5, 217, 174, 0.3490196078);
  }
}
@media screen and (max-width: 768px) {
  #root .trend-middle-box {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 35px;
       -moz-column-gap: 35px;
            column-gap: 35px;
  }
  #root .trend-middle-box > .skpos-logo-wrapper > .top-line {
    top: -15px;
    left: -18px;
  }
  #root .trend-middle-box > .skpos-logo-wrapper > .bottom-line {
    bottom: -19px;
    right: -15px;
  }
  #root .trend-middle-box > .skpos-logo-wrapper > .skpos-logo {
    width: 84px;
    height: 84px;
    row-gap: 4px;
    -webkit-box-shadow: 0 0 13px 13px rgba(5, 217, 174, 0.3490196078);
            box-shadow: 0 0 13px 13px rgba(5, 217, 174, 0.3490196078);
  }
}