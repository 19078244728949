#root .main-banner-section {
  position: relative;
  width: 100%;
  height: 58vw;
  max-height: 1000px;
}
#root .main-banner-section > .main-img-wrapper {
  overflow: hidden;
  height: 100%;
}
#root .main-banner-section > .main-img-wrapper > .slide {
  width: 100%;
  max-height: 1000px;
  top: 0;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  height: 100%;
}
#root .main-banner-section > .main-img-wrapper .slide.active {
  opacity: 1;
}
#root .main-banner-section > .content-wrapper {
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  top: 200px;
  left: 180px;
  white-space: pre;
  font-weight: 800;
}
#root .main-banner-section > .content-wrapper > .sub-text {
  font-size: 32px;
  font-weight: 800;
  line-height: 41.6px;
}
#root .main-banner-section > .content-wrapper > .main-content {
  margin-top: 16px;
  font-size: 48px;
  line-height: 62.4px;
}
#root .main-banner-section > .content-wrapper > .store-img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-banner-section > .main-img-wrapper {
    width: 100%;
  }
  #root .main-banner-section > .main-img-wrapper > .slide {
    width: 100%;
  }
  #root .main-banner-section > .content-wrapper {
    top: 75px;
    left: 36px;
  }
  #root .main-banner-section > .content-wrapper > .sub-text {
    font-size: 25px;
    line-height: 32.5px;
  }
  #root .main-banner-section > .content-wrapper > .main-content {
    font-size: 35px;
    line-height: 45.5px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-banner-section {
    height: 445px;
  }
  #root .main-banner-section > .main-img-wrapper {
    height: 445px;
  }
  #root .main-banner-section > .main-img-wrapper > .slide {
    height: 445px;
  }
  #root .main-banner-section > .content-wrapper {
    top: 50px;
    left: 16px;
  }
  #root .main-banner-section > .content-wrapper > .sub-text {
    font-size: 19px;
    line-height: 24.7px;
  }
  #root .main-banner-section > .content-wrapper > .main-content {
    font-size: 30px;
    line-height: 42px;
  }
  #root .main-banner-section > .content-wrapper > .store-img-wrapper {
    row-gap: 9px;
  }
  #root .main-banner-section > .content-wrapper > .store-img-wrapper .google {
    width: 144px;
  }
  #root .main-banner-section > .content-wrapper > .store-img-wrapper .apple {
    width: 128px;
  }
}