#root .alert-talk-section3 {
  padding: 180px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #363840;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .alert-talk-section3 > .alert-talk-section3-wrapper {
  width: 1220px;
  height: 100%;
  margin: 0 auto;
}
#root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: white;
  row-gap: 10px;
}
#root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper > .main-text {
  font-size: 48px;
  font-weight: 700;
}
#root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper > .sub-text {
  font-size: 24px;
  font-weight: 400;
}
#root .alert-talk-section3 > .alert-talk-section3-wrapper > .marketing-img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 55px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .alert-talk-section3 > .alert-talk-section3-wrapper > .marketing-img-wrapper > .right-wrapper img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-section3 > .alert-talk-section3-wrapper {
    width: 690px;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper > .main-text {
    font-size: 34px;
    font-weight: 700;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .marketing-img-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .marketing-img-wrapper > .right-wrapper {
    margin-top: 32px;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-section3 {
    padding: 132px 0px;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper {
    max-width: 328px;
    width: 100%;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: pre-line;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper > .main-text {
    text-align: center;
    font-size: 30px;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .alert-talk-section3-text-wrapper > .sub-text {
    text-align: center;
    font-size: 20px;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .marketing-img-wrapper {
    margin-top: 54px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .marketing-img-wrapper > .left-wrapper {
    width: 100%;
  }
  #root .alert-talk-section3 > .alert-talk-section3-wrapper > .marketing-img-wrapper > .right-wrapper {
    margin-top: 32px;
  }
}