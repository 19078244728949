#root .inquiry-toggle-wrapper {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 12px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 2px solid var(--grey200);
}
#root .inquiry-toggle-wrapper > .inquiry-toggle-list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  font-weight: 700;
  text-align: center;
}