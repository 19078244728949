@charset "UTF-8";
#root .main-section3 {
  padding: 180px 0px;
  background-color: #01A0A0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 40px;
}
#root .main-section3 > .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  color: white;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .main-section3 > .content-wrapper > .main-content {
  font-size: 48px;
  font-weight: 700;
}
#root .main-section3 > .content-wrapper > .sub-content {
  font-size: 24px;
  font-weight: 500;
  white-space: pre;
  text-align: center;
  line-height: 36px;
}
#root .main-section3 > .selector-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .main-section3 > .selector-menu > .color-btn svg path {
  fill: white;
}
#root .main-section3 > .selector-menu > .color-btn:nth-child(3) svg path {
  fill: unset;
  stroke: white;
}
#root .main-section3 > .selector-menu > .selector-btn {
  width: 222px;
  height: 76px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  border-radius: 10px;
  background-color: #E6FBF7;
}
#root .main-section3 > .selector-menu > .selector-btn .text {
  color: #01A0A0;
  font-size: 26px;
  font-weight: 700;
}
#root .main-section3 > .selector-card-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section3 {
    padding-top: 146px;
    padding-bottom: 130px;
    row-gap: 58px;
  }
  #root .main-section3 > .content-wrapper {
    row-gap: 10px;
  }
  #root .main-section3 > .content-wrapper > .main-content {
    font-size: 34px;
  }
  #root .main-section3 > .content-wrapper > .sub-content {
    font-size: 24px;
  }
  #root .main-section3 > .selector-menu {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
  #root .main-section3 > .selector-menu > .selector-btn {
    width: 222px;
    height: 76px;
    font-size: 26px;
  }
  #root .main-section3 > .selector-card-box {
    width: 604px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section3 {
    padding-top: 75px;
    padding-bottom: 80px;
    row-gap: 32px;
  }
  #root .main-section3 > .content-wrapper > .main-content {
    font-size: 30px;
  }
  #root .main-section3 > .content-wrapper > .sub-content {
    font-size: 20px;
    line-height: 30px;
  }
  #root .main-section3 > .selector-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 16px;
  }
  #root .main-section3 > .selector-menu > .selector-btn {
    height: 60px;
  }
  #root .main-section3 > .selector-menu > .selector-btn .text {
    font-size: 20px;
  }
  #root .main-section3 .selector-card-box::-webkit-scrollbar {
    display: none;
  }
  #root .main-section3 .selector-card-box {
    -ms-overflow-style: none;
    /* 인터넷 익스플로러 */
    scrollbar-width: none;
    /* 파이어폭스 */
  }
  #root .main-section3 > .selector-card-box {
    width: 320px;
    padding-left: 90px;
    padding-right: 40px;
    overflow-y: scroll;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    border-radius: 10px;
  }
}