#root .store-card {
  width: 100%;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  border-radius: 10px;
}
#root .store-card > .content-text {
  font-weight: 500;
  font-size: 24px;
  color: #363840;
}

@media screen and (max-width: 768px) {
  #root .store-card > .content-text {
    font-size: 18px;
    white-space: pre;
    line-height: 32.4px;
  }
}