#root .kakao-leaflet-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 11px;
}
#root .kakao-leaflet-wrapper > .leaflet-text {
  color: white;
  font-size: 28px;
  font-weight: 700;
}
#root .kakao-leaflet-wrapper > .leaflet-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
#root .kakao-leaflet-wrapper > .leaflet-btn-wrapper > .leaflet-btn {
  padding: 5px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 100px;
  background-color: #949494;
  font-size: 24px;
  font-weight: 700;
  color: white;
}

@media screen and (max-width: 767px) {
  #root .kakao-leaflet-wrapper > .leaflet-text {
    font-size: 20px;
  }
  #root .kakao-leaflet-wrapper > .leaflet-btn-wrapper {
    -webkit-column-gap: 17px;
       -moz-column-gap: 17px;
            column-gap: 17px;
  }
  #root .kakao-leaflet-wrapper > .leaflet-btn-wrapper > .leaflet-btn {
    font-size: 20px;
  }
}