#root .marketing-toggle-btn {
  width: 368px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-top: 1px solid white;
  padding: 18px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  row-gap: 8px;
}
#root .marketing-toggle-btn > .main-toggle-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .marketing-toggle-btn > .main-toggle-btn img {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#root .marketing-toggle-btn > .main-toggle-btn > .main-text {
  font-size: 32px;
  font-weight: 700;
  color: white;
}
#root .marketing-toggle-btn > .main-toggle-btn > .color-main-text {
  color: var(--main);
}
#root .marketing-toggle-btn > .sub-text {
  white-space: pre-line;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#root .marketing-toggle-open {
  height: 273px;
  border-top: 1px solid var(--main);
}
#root .marketing-toggle-open > .main-toggle-btn img {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .marketing-toggle-btn {
    width: 100%;
  }
  #root .marketing-toggle-btn > .main-toggle-btn > .main-text {
    font-size: 30px;
  }
  #root .marketing-toggle-btn > .sub-text {
    font-size: 20px;
  }
  #root .marketing-toggle-open {
    height: auto;
    padding-bottom: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}
@media screen and (max-width: 767px) {
  #root .marketing-toggle-btn {
    width: 100%;
  }
  #root .marketing-toggle-btn > .main-toggle-btn > .main-text {
    font-size: 24px;
  }
  #root .marketing-toggle-btn > .sub-text {
    font-size: 20px;
  }
  #root .marketing-toggle-open {
    height: auto;
    padding-bottom: 48px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}