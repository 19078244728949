#root .custom-input-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .custom-input-wrapper > .label {
  font-size: 14px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .custom-input-wrapper > .label > .sub-label {
  font-size: 14px;
  font-weight: 400;
  color: var(--mint800);
}
#root .custom-input-wrapper > .custom-input {
  border: 1px solid #DBDBDB;
  width: 100%;
  height: 50px;
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
}