#root .technology-wrapper {
  background-color: var(--grey100);
  padding-top: 116px;
  padding-bottom: 160px;
}
#root .technology-wrapper > .technology-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 46px;
}
#root .technology-wrapper > .technology-container > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 5px;
  text-align: center;
}
#root .technology-wrapper > .technology-container > .title-container > .color-text {
  color: var(--main);
  font-size: 18px;
  font-weight: 700;
}
#root .technology-wrapper > .technology-container > .title-container > .bold-text {
  font-size: 34px;
  font-weight: 800;
  line-height: 140%;
}
#root .technology-wrapper > .technology-container > .title-container > .bold-text > .bold-color-text {
  color: var(--main);
}
#root .technology-wrapper > .technology-container > .circle-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .technology-wrapper > .technology-container > .circle-container > .circle-card {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .technology-wrapper > .technology-container > .circle-container > .circle-card img {
  width: 164px;
}

@media screen and (max-width: 1439px) {
  #root .technology-wrapper > .technology-container .title-container .bold-text {
    padding-top: 4px;
    font-size: 26px;
    line-height: 36.4px;
  }
  #root .technology-wrapper > .technology-container > .circle-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 25px;
       -moz-column-gap: 25px;
            column-gap: 25px;
    row-gap: 36px;
    margin: 0 auto;
  }
  #root .technology-wrapper > .technology-container > .circle-container .circle-card {
    width: 190px;
    height: 190px;
  }
  #root .technology-wrapper > .technology-container > .circle-container .circle-card img {
    width: 135px;
  }
}
@media screen and (max-width: 767px) {
  #root .technology-wrapper {
    padding: 74px 16px;
  }
  #root .technology-wrapper > .technology-container {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .technology-wrapper > .technology-container .circle-container {
    -webkit-column-gap: 14px;
       -moz-column-gap: 14px;
            column-gap: 14px;
  }
  #root .technology-wrapper > .technology-container .circle-container .circle-card {
    width: 100px;
    height: 100px;
  }
  #root .technology-wrapper > .technology-container .circle-container .circle-card img {
    width: 71.346px;
  }
}