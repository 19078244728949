#root .first-popup {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .first-popup .first-popup-box {
  -webkit-animation: inside 0.2s ease-in;
          animation: inside 0.2s ease-in;
  max-width: 457px;
  min-width: 320px;
  width: 95%;
  background-color: white;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px 12px 28px 12px;
  row-gap: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
#root .first-popup .first-popup-box > .cancel-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}
#root .first-popup .first-popup-box > .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .first-popup .first-popup-box > .sub {
  font-size: 13px;
  color: var(--grey500);
  white-space: pre-line;
  font-weight: 400;
  line-height: 150%;
}
#root .first-popup .first-popup-box > .down-btn-wrapper {
  display: grid;
  grid-template-columns: 256px;
  grid-template-rows: repeat(2, 50px);
  row-gap: 8px;
}
#root .first-popup .first-popup-box > .down-btn-wrapper .first-popup-link {
  width: 256px;
  height: 50px;
  background-color: var(--main);
  color: white;
  border-radius: 5px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700;
}
#root .first-popup .first-popup-box > .down-btn-wrapper > .popup-btn {
  background-color: unset;
  color: var(--grey500);
}