#root .circle-card {
  width: 171px;
  height: 171px;
  border-radius: 50%;
  background-color: #05D9AE;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 28px;
  font-weight: 700;
  white-space: pre;
  line-height: 37.65px;
  z-index: 1;
  text-align: center;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .circle-card {
    width: 121px;
    height: 121px;
    font-size: 20px;
    line-height: 26px;
  }
}
@media screen and (max-width: 768px) {
  #root .circle-card {
    width: 96px;
    height: 96px;
    font-size: 16px;
    line-height: 20.8px;
  }
}