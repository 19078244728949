#root .content-card {
  width: 415px;
  height: 522px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
  background-color: #0bb8a8;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
#root .content-card > .top-box {
  height: 277px;
  padding: 30px 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  row-gap: 10px;
}
#root .content-card > .top-box .sub-text {
  color: white;
  font-size: 24px;
  font-weight: 700;
}
#root .content-card > .top-box .main-text {
  color: white;
  font-weight: 700;
  font-size: 32px;
  white-space: pre;
  line-height: 130%;
}
#root .content-card > img {
  width: 100%;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .content-card {
    width: 296px;
    height: 372px;
  }
  #root .content-card > .top-box {
    padding: 21px;
    row-gap: 7px;
  }
  #root .content-card > .top-box .sub-text {
    font-size: 18px;
  }
  #root .content-card > .top-box .main-text {
    line-height: 29.9px;
    font-size: 23px;
  }
}
@media screen and (max-width: 768px) {
  #root .content-card {
    width: 239px;
    height: 301px;
  }
  #root .content-card > .top-box {
    height: 160px;
    padding: 17px;
  }
  #root .content-card > .top-box > .sub-text {
    font-size: 15px;
  }
  #root .content-card > .top-box > .main-text {
    font-size: 19px;
    line-height: 24px;
  }
}