#root .selector-card {
  width: 290px;
  height: 393px;
  padding: 26px;
  background-color: white;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .selector-card > .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .selector-card > .img-box img {
  width: 80px;
  height: 80px;
}
#root .selector-card > .img-box > .title {
  margin-top: 14px;
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 36px;
  white-space: pre;
  font-weight: 700;
}
#root .selector-card > .img-box > .sub-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--grey600);
}
#root .selector-card > .no-img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .selector-card > .no-img-box > .title {
  color: #01A0A0;
  font-weight: 700;
  font-size: 30px;
  white-space: pre;
  line-height: 36px;
}
#root .selector-card > .no-img-box > .sub-title {
  white-space: pre;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .selector-card {
    width: 290px;
    height: 393px;
  }
}
@media screen and (max-width: 768px) {
  #root .selector-card {
    width: 270px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
}