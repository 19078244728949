#root .toast {
  position: fixed;
  bottom: -300px;
  width: 500px;
  padding: 10px;
  background: var(--main);
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  color: white;
  -webkit-transition: bottom 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out;
  z-index: 3500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
#root .toast-on {
  bottom: 30px;
}
#root .error-toast {
  background-color: var(--grey500);
}

@media screen and (max-width: 767px) {
  #root .toast {
    width: 90%;
  }
}