#root .inquiry-button {
  background-color: #0BB8A8;
  width: 263px;
  height: 66px;
  border: none;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 700;
}