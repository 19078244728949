#root .main-section11 {
  padding: 180px 0px;
  background-color: #0BB8A8;
}
#root .main-section11 > .wrapper {
  width: 1080px;
  margin: 0 auto;
}
#root .main-section11 > .wrapper > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  text-align: center;
  color: white;
}
#root .main-section11 > .wrapper > .text-wrapper > .text {
  font-weight: 700;
  font-size: 48px;
}
#root .main-section11 > .wrapper > .text-wrapper > .sub-text {
  font-size: 24px;
  font-weight: 500;
}
#root .main-section11 > .wrapper > .card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 930px;
  gap: 24px;
  margin: 0 auto;
  margin-top: 50px;
}

@media screen and (max-width: 1430px) and (min-width: 769px) {
  #root .main-section11 {
    padding-top: 152px;
    padding-bottom: 158px;
  }
  #root .main-section11 > .wrapper {
    width: 688px;
  }
  #root .main-section11 > .wrapper > .text-wrapper > .text {
    font-size: 34px;
  }
  #root .main-section11 > .wrapper > .text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .main-section11 > .wrapper > .card-wrapper {
    margin-top: 76px;
    width: 695px;
    gap: 18px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section11 {
    padding-top: 112px;
    padding-bottom: 96px;
  }
  #root .main-section11 > .wrapper {
    width: 328px;
  }
  #root .main-section11 > .wrapper > .text-wrapper {
    row-gap: 8px;
  }
  #root .main-section11 > .wrapper > .text-wrapper > .text {
    font-size: 30px;
  }
  #root .main-section11 > .wrapper > .text-wrapper > .sub-text {
    font-size: 22px;
  }
  #root .main-section11 > .wrapper > .card-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}