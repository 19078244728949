#root .main-section8 {
  padding: 180px 9px;
  background-color: #363840;
}
#root .main-section8 > .wrapper {
  width: 1094px;
  margin: 0 auto;
}
#root .main-section8 > .wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: white;
  font-size: 48px;
  font-weight: 700;
  white-space: pre;
  line-height: 62.4px;
  margin-bottom: 80px;
}
#root .main-section8 > .wrapper > .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 126px;
}
#root .main-section8 > .wrapper > .content-wrapper > .main-section8-card:nth-child(2) > .card {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section8 {
    padding-top: 118px;
    padding-bottom: 93px;
  }
  #root .main-section8 > .wrapper {
    width: 690px;
  }
  #root .main-section8 > .wrapper > .title-wrapper {
    font-size: 34px;
    line-height: 44.2px;
    margin-bottom: 70px;
  }
  #root .main-section8 > .wrapper > .content-wrapper {
    row-gap: 33px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section8 {
    padding: 90px 0px;
  }
  #root .main-section8 > .wrapper {
    width: 328px;
  }
  #root .main-section8 > .wrapper > .title-wrapper {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 44px;
  }
  #root .main-section8 > .wrapper > .content-wrapper {
    row-gap: 59px;
  }
  #root .main-section8 > .wrapper > .content-wrapper > .main-section8-card:nth-child(2) > .content-text {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}