#root .main-section7 {
  padding: 180px 0px;
  background-color: #F5F6F8;
}
#root .main-section7 > .wrapper {
  width: 1075px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .main-section7 > .wrapper > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .main-section7 > .wrapper > .text-wrapper > .text {
  font-size: 48px;
  font-weight: 700;
}
#root .main-section7 > .wrapper > .text-wrapper > .sub-text {
  white-space: pre;
  color: var(--grey600);
  line-height: 36px;
  font-size: 24px;
  font-weight: 500;
}
#root .main-section7 > .wrapper > .pay-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 16px;
  width: 610px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section7 {
    padding-top: 102px;
    padding: 100px;
  }
  #root .main-section7 > .wrapper {
    width: 602px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 32px;
  }
  #root .main-section7 > .wrapper > .text-wrapper {
    row-gap: 10px;
  }
  #root .main-section7 > .wrapper > .text-wrapper > .text {
    font-size: 34px;
  }
  #root .main-section7 > .wrapper > .text-wrapper > .sub-text {
    line-height: 36px;
    font-size: 24px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section7 {
    padding: 98px 0px;
  }
  #root .main-section7 > .wrapper {
    width: 328px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 54px;
  }
  #root .main-section7 > .wrapper > .text-wrapper > .text {
    font-size: 34px;
  }
  #root .main-section7 > .wrapper > .text-wrapper > .sub-text {
    font-size: 22px;
    line-height: 33px;
    text-align: center;
  }
  #root .main-section7 > .wrapper > .pay-wrapper {
    width: 328px;
    gap: 8px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}