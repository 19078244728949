#root .pay-card {
  width: 190px;
  height: 190px;
  border-radius: 10px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .pay-card img {
  width: 130px;
  height: 60px;
}
#root .pay-card > .barcode {
  width: 60px;
  height: 60px;
}
#root .pay-card > .cash {
  width: 60px;
  height: 60px;
}
#root .pay-card > .qr-code {
  width: 60px;
  height: 60px;
}
#root .pay-card > .pay-text {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #root .pay-card {
    width: 160px;
    height: 160px;
    padding: 15px;
  }
}