#root .main-section8-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .main-section8-card > .card {
  border-radius: 10px;
  background-color: #CDF7EF;
  padding: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .main-section8-card > .content-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
  color: white;
}
#root .main-section8-card > .content-text > .title {
  font-size: 40px;
  font-weight: 700;
}
#root .main-section8-card > .content-text > .sub-text {
  white-space: pre;
  line-height: 36px;
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section8-card {
    -webkit-column-gap: 39px;
       -moz-column-gap: 39px;
            column-gap: 39px;
  }
  #root .main-section8-card > .content-text > .title {
    font-size: 26px;
  }
  #root .main-section8-card > .content-text > .sub-text {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section8-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .main-section8-card > .card {
    padding: 19px;
  }
  #root .main-section8-card > .content-text {
    width: 100%;
    margin-top: 16px;
  }
  #root .main-section8-card > .content-text > .title {
    font-size: 26px;
  }
  #root .main-section8-card > .content-text > .sub-text {
    font-size: 20px;
    line-height: 30px;
  }
}