@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@-webkit-keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes topside {
  from {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes topside {
  from {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes rightside {
  from {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes rightside {
  from {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.gmarket {
  font-family: "GmarketSans", sans-serif;
}

.montserrat {
  font-family: "Montserrat", serif;
  font-weight: 400;
}

.react-loading-skeleton {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

* {
  --main: #05D9AE;
  --mint900: #027C8C;
  --mint800: #01A0A0;
  --mint700: #0BB8A8;
  --mint500: #37E1BE;
  --mint400: #69E8CE;
  --mint300: #9BF0DF;
  --mint200: #CDF7EF;
  --mint100: #E6FBF7;
  --blue900: #2239B8;
  --blue800: #1C5BD8;
  --blue700: #146DEA;
  --blue500: #008EFF;
  --blue400: #279FFF;
  --blue300: #56B0FF;
  --blue200: #8AC7FF;
  --blue100: #B9DCFF;
  --blue50: #E2F1FF;
  --orange600: #FFB000;
  --orange100: #FFF7E1;
  --grey900: #1A1B23;
  --grey800: #24252C;
  --grey700: #2D2E36;
  --grey600: #242627;
  --grey500: #54585a;
  --grey400: #949494;
  --grey300: #c3c3c3;
  --grey200: #dbdbdb;
  --grey100: #f5f6f8;
  --red600: #E74133;
  --red100: #FDECEB;
  --white: #ffffff;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  font-family: "pretendard", sans-serif;
}
* [placeholder]:empty:focus::before {
  content: "";
}
* [contenteditable=true] {
  outline: unset;
}
* [contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  /* For Firefox */
  color: #bfbfbf;
  font-size: 17px;
  font-family: "pretendard", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "pretendard", sans-serif;
  color: black;
}

main {
  min-height: 100vh;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

input,
textarea {
  font-size: 15px;
}

input,
p,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  resize: none;
  font-family: "pretendard", sans-serif;
}
input::-webkit-input-placeholder, p::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-moz-placeholder, p::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:-ms-input-placeholder, p:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-ms-input-placeholder, p::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::placeholder,
p::placeholder,
textarea::placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:focus,
p:focus,
textarea:focus {
  outline: none;
}

button,
a,
label {
  padding: unset;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  background-color: unset;
  color: black;
}
button:focus,
a:focus,
label:focus {
  outline: none;
}

#root {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
}
#root .hidden-section {
  opacity: 0;
  -webkit-transform: translateY(150px);
          transform: translateY(150px);
  -webkit-transition: opacity 0.9s ease-out, -webkit-transform 0.5s ease-out;
  transition: opacity 0.9s ease-out, -webkit-transform 0.5s ease-out;
  transition: opacity 0.9s ease-out, transform 0.5s ease-out;
  transition: opacity 0.9s ease-out, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
#root .hidden {
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  /* 초기 상태에서 아래에 배치 */
}
#root .slide-up {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

@media screen and (min-width: 768px) {
  button:hover,
  a:hover,
  label:hover {
    opacity: 0.7;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
}