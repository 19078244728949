#root .industry-page {
  padding-top: 66px;
}
#root .industry-page > .section2 {
  padding-top: 98px;
  padding-bottom: 256px;
}
#root .industry-page > .section2 > .section2-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 98px;
}
#root .industry-page > .section2 > .section2-wrapper .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
}
#root .industry-page > .section2 > .section2-wrapper .title-container .sub-title {
  text-align: center;
  color: var(--main);
  font-weight: 700;
  font-size: 18px;
}
#root .industry-page > .section2 > .section2-wrapper .title-container .main-title {
  font-size: 34px;
  font-weight: 800;
  line-height: 47.6px;
  text-align: center;
}
#root .industry-page > .section2 > .section2-wrapper .title-container .main-title p {
  font-weight: 400;
  text-align: center;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 32px;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container > .card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container > .card-container > .card {
  width: 320px;
  height: 302px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--grey100);
  padding: 24px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container > .card-container > .card > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 30px;
  font-weight: 700;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container > .card-container > .card > .title img {
  width: 44px;
  height: 44px;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container > .card-container > .card > .sub-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container > .card-container > .card > .sub-title > .bold-text {
  font-weight: 700;
  font-size: 16px;
}
#root .industry-page > .section2 > .section2-wrapper > .industry-card-container > .card-container > .card > .sub-title > .basic-text {
  font-size: 14px;
  font-weight: 400;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 38px;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  gap: 20px;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card {
  width: 320px;
  height: 530px;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .detail-container {
  padding: 22px 24px;
  height: 325px;
  background-color: var(--grey700);
  border-radius: 10px 10px 0px 0px;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .detail-container > .hashtag-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .detail-container > .hashtag-box > .hashtag {
  padding: 4px 12px;
  border-radius: 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  color: white;
  font-weight: 700;
  background-color: var(--main);
  font-size: 18px;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .detail-container > .hashtag-box > .text {
  color: var(--main);
  font-weight: 700;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .detail-container > .text-box {
  margin-top: 28px;
  color: white;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .detail-container > .text-box > .title {
  font-size: 24px;
  font-weight: 700;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .detail-container > .text-box > .sub-title {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .img-box {
  width: 100%;
}
#root .industry-page > .section2 > .section2-wrapper > .review-container > .card-container > .card > .img-box img {
  width: 100%;
  height: 160px;
}

@media screen and (max-width: 1439px) {
  #root .industry-page .section2 .section2-wrapper .industry-card-container .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  #root .industry-page .section2 .section2-wrapper .industry-card-container .card-container .card:nth-child(3) {
    position: relative;
    left: 50%;
  }
  #root .industry-page .section2 .section2-wrapper .review-container .card-container {
    display: grid;
    padding: 0px 53px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  #root .industry-page .section2 .section2-wrapper {
    row-gap: 87px;
  }
  #root .industry-page .section2 .section2-wrapper .title-container .main-title {
    font-size: 24px;
    font-weight: 800;
    line-height: 36.4px;
  }
  #root .industry-page .section2 .section2-wrapper .industry-card-container .card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  #root .industry-page .section2 .section2-wrapper .industry-card-container .card-container .card:nth-child(3) {
    position: relative;
    left: 0;
  }
  #root .industry-page .section2 .section2-wrapper .review-container .card-container {
    display: grid;
    padding: 0px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    grid-template-columns: repeat(1, 1fr);
  }
}