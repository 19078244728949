#root .privacy-page {
  padding-top: 200px;
}
#root .privacy-page > .privacy-wrapper {
  margin: 0 auto;
  padding: 0px 30px;
  padding-bottom: 90px;
}
#root .privacy-page > .privacy-wrapper > .titl-wrapper {
  font-size: 30px;
  font-weight: 700;
}
#root .privacy-page > .privacy-wrapper > .privacy-description {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  justify-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 37px 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .privacy-page > .privacy-wrapper > .privacy-description > .privacy-detail {
  font-size: 17px;
  color: var(--main);
}
#root .privacy-page > .privacy-wrapper > .privacy-description > .title {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 700;
}
#root .privacy-page > .privacy-wrapper > .privacy-description > .content {
  margin-top: 15px;
  white-space: pre-wrap;
  line-height: 2;
  font-size: 17px;
  color: rgb(38, 38, 38);
}

@media screen and (max-width: 767px) {
  #root .privacy-page {
    padding-top: 100px;
  }
  #root .privacy-page > .privacy-wrapper {
    margin: 0 auto;
    padding: 0px 10px;
    padding-bottom: 90px;
  }
  #root .privacy-page > .privacy-wrapper > .privacy-description {
    padding: 20px;
  }
  #root .privacy-page > .privacy-wrapper > .privacy-description > .privacy-detail {
    font-size: 12px;
    line-height: 1.5;
  }
  #root .privacy-page > .privacy-wrapper > .privacy-description > .content {
    font-size: 12px;
    white-space: normal;
  }
}