#root .marketing-promotion-lists-section {
  background-color: #F5F6F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 180px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists {
  width: 912px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .text-wrapper > .text {
  font-size: 48px;
  font-weight: 700;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .text-wrapper > .sub-text {
  font-size: 24px;
  font-weight: 500;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .img-box {
  width: 912px;
  height: 372px;
  background-color: #01A0A0;
  border-radius: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .img-box > .img {
  position: absolute;
  bottom: 0;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .card-wrapper {
  margin-top: 54px;
  width: 100%;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .card-wrapper > .card-title {
  font-size: 32px;
  font-weight: 700;
  color: #363840;
  margin-bottom: 16px;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .card-wrapper > .card-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}
#root .marketing-promotion-lists-section > .marketing-promotion-lists > .card-wrapper > .button-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 60px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .marketing-promotion-lists-section {
    padding: 160px 0px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists {
    width: 688px;
    row-gap: 48px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .text-wrapper > .text {
    font-size: 34px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .img-box {
    width: 688px;
    height: 280px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .card-wrapper {
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  #root .marketing-promotion-lists-section > .marketing-promotion-lists {
    width: 328px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .text-wrapper > .text {
    font-size: 30px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .text-wrapper > .sub-text {
    font-size: 22px;
    line-height: 33px;
    white-space: pre;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .img-box {
    width: 328px;
    height: 168px;
  }
  #root .marketing-promotion-lists-section > .marketing-promotion-lists > .card-wrapper > .card-title {
    font-size: 26px;
  }
}