#root .faq-page {
  padding-top: 140px;
}
#root .faq-page .faq-search-section > .faq-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .faq-page .faq-search-section > .faq-search-wrapper > .faq-search-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .faq-page .faq-search-section > .faq-search-wrapper > .faq-search-container > .faq {
  font-size: 26px;
  font-weight: 700;
}
#root .faq-page .faq-search-section > .faq-search-wrapper > .faq-search-container > .search-form {
  width: 727px;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  padding: 0 13px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .faq-page .faq-search-section > .faq-search-wrapper > .faq-search-container > .search-form > .search-input {
  font-size: 15px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .faq-page .faq-search-section > .faq-search-wrapper > .faq-search-container > .search-form > .search-button > .glass-img {
  width: 23px;
  height: 23px;
}
#root .faq-page .faq-lists-section {
  padding-bottom: 97px;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper {
  padding-top: 40px;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .line {
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1070px;
  margin: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper {
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .list-box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 18px 5px 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #DBDBDB;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .list-box > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .list-box > .title-container > .circle {
  width: 21px;
  height: 21px;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .list-box > .title-container > .circle img {
  width: 10px;
  height: 11px;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .list-box > .title-container .question-title {
  font-size: 15px;
  font-weight: 700;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .list-box > .toggle-button {
  width: 21px;
  height: 21px;
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .list-box > .toggle-open {
  -webkit-transform: unset;
          transform: unset;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .description-box {
  padding: 15px 14px;
  background-color: #F5F6F8;
  border-bottom: 1px solid #D9D9D9;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .faq-list-box-wrapper > .description-box > p {
  white-space: pre-line;
  font-size: 15px;
  font-weight: 400;
  line-height: 160%;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container .open-faq {
  max-height: 800px;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .inquiry-button {
  width: 304px;
  height: 50px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 5px;
  background-color: var(--mint800);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0px 15px;
}
#root .faq-page .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .inquiry-button p {
  color: white;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 1439px) {
  #root .faq-page > .faq-search-section {
    padding: 0px 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .faq-page > .faq-search-section > .faq-search-wrapper {
    -webkit-box-align: normal;
        -ms-flex-align: normal;
            align-items: normal;
  }
  #root .faq-page > .faq-search-section > .faq-search-wrapper > .faq-search-container > .search-form {
    width: 100%;
  }
  #root .faq-page > .faq-lists-section {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .faq-page > .faq-lists-section > .faq-lists-wrapper > .faq-lists-container {
    padding: 0 40px;
  }
  #root .faq-page > .faq-lists-section > .faq-lists-wrapper > .faq-lists-container > .lists-container {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  #root .faq-page > .faq-search-section {
    padding: 0 20px;
  }
  #root .faq-page > .faq-lists-section > .faq-lists-wrapper > .faq-lists-container {
    padding: 0 20px;
  }
}