#root .pc-top-trend-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 125px;
  position: relative;
  bottom: -75px;
}
#root .pc-top-trend-box > .dot-line-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 76px;
}
#root .pc-top-trend-box > .dot-line-box > .sm-circle {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  background-color: #05D9AE;
}
#root .pc-top-trend-box > .dot-line-box > .dot-line {
  height: 63px;
  border: 1px dashed #05D9AE;
}
#root .pc-top-trend-box > .circle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  top: 4px;
  -webkit-column-gap: 35px;
     -moz-column-gap: 35px;
          column-gap: 35px;
}
#root .pc-top-trend-box > .circle-wrapper .circle-card:nth-child(3) {
  position: relative;
  top: -100px;
  z-index: 1;
}
#root .pc-top-trend-box > .circle-wrapper > .line {
  top: -20px;
  position: absolute;
  left: 80px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .pc-top-trend-box {
    top: 110px;
  }
  #root .pc-top-trend-box > .dot-line-box {
    top: 21px;
  }
  #root .pc-top-trend-box > .dot-line-box > .dot-line {
    height: 60px;
  }
  #root .pc-top-trend-box > .circle-wrapper > .line {
    top: -50px;
    left: 70px;
  }
}