#root .bubble-comp {
  position: relative;
  width: 303.18px;
  height: 122.68px;
  padding: 0px;
  background: #FFFFFF;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 7.24px;
}
#root .bubble-comp > .title {
  color: #05D9AE;
  font-size: 21.72px;
  font-weight: 700;
}
#root .bubble-comp > .sub-title {
  font-size: 14.48px;
  line-height: 21px;
  font-weight: 400;
  white-space: pre;
  text-align: center;
}
#root .left-bubble:after {
  margin: 0 auto;
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  bottom: 60px;
  left: 327px;
}
#root .right-bubble:after {
  margin: 0 auto;
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  bottom: 60px;
  left: -18px;
}
#root .bottom-bubble:after {
  margin: 0 auto;
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  bottom: 135px;
  left: 159px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .bubble-comp {
    width: 212px;
    height: 86px;
  }
  #root .bubble-comp > .title {
    font-size: 16px;
  }
  #root .bubble-comp > .sub-title {
    line-height: 18px;
    font-size: 12px;
  }
  #root .bubble-comp:after {
    left: 92px;
  }
  #root .left-bubble:after {
    bottom: 35px;
    left: 204px;
  }
  #root .right-bubble:after {
    bottom: 35px;
    left: -22px;
  }
  #root .bottom-bubble:after {
    bottom: 86px;
    left: 95px;
  }
}
@media screen and (max-width: 768px) {
  #root .bubble-comp {
    width: 184px;
    height: 79px;
    row-gap: 4px;
    padding: 16px 9px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .bubble-comp > .title {
    font-size: 13px;
  }
  #root .bubble-comp > .sub-title {
    font-size: 13px;
    line-height: 16.9px;
  }
  #root .bubble-comp:after {
    left: 90px;
    bottom: -10px;
    border-width: 12px 6px 0;
  }
  #root .left-bubble:after {
    bottom: 67px;
    left: 98px;
  }
  #root .right-bubble:after {
    bottom: 65px;
    left: -12px;
  }
  #root .bottom-bubble:after {
    bottom: 79px;
    left: 90px;
  }
  #root .left-bubble,
  #root .right-bubble {
    width: 99px;
    height: 143px;
    padding: 16px 9px;
  }
}