#root .inquiry-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .inquiry-wrapper > .textarea-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .inquiry-wrapper > .textarea-box > .label {
  font-size: 14px;
  font-weight: 700;
}
#root .inquiry-wrapper > .textarea-box > .description {
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  height: 162px;
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}