#root .bottom-section {
  background-color: var(--grey900);
}
#root .bottom-section > .bottom-container {
  padding: 160px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .bottom-section > .bottom-container > .description-container {
  max-width: 1255px;
  height: 162px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 48px;
     -moz-column-gap: 48px;
          column-gap: 48px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .left-container > .inquiry-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
  margin-top: 20px;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .left-container > .inquiry-wrapper > .text {
  font-size: 34px;
  font-weight: 700;
  color: white;
  line-height: 140%;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .left-container > .inquiry-wrapper > .inquiry-btn {
  width: 213px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  color: black;
  font-size: 16px;
  font-weight: 700;
  background-color: white;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .right-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 26px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .right-container > .guide-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 26px;
     -moz-column-gap: 26px;
          column-gap: 26px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .right-container > .guide-container > .box {
  width: 191px;
  height: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--main);
  border-radius: 50px;
  text-align: center;
  font-weight: 700;
  color: black;
  font-size: 18px;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .right-container > .guide-container .description {
  color: white;
  font-size: 18px;
  font-weight: 500;
  white-space: pre-wrap;
  text-align: left;
  line-height: 150%;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .right-container > .guide-container .description > .mb {
  display: none;
}
#root .bottom-section > .bottom-container > .description-container > .service-center-container > .line {
  height: 100%;
  border-right: 1px dashed white;
}

@media screen and (max-width: 1439px) {
  #root .bottom-section .line {
    display: none;
  }
  #root .bottom-section > .bottom-container .description-container {
    max-width: 688px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .bottom-section > .bottom-container .description-container .text {
    width: 220px;
  }
  #root .bottom-section > .bottom-container .description-container > .service-center-container {
    -webkit-column-gap: 60px;
       -moz-column-gap: 60px;
            column-gap: 60px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .bottom-section > .bottom-container .description-container > .service-center-container > .left-container .inquiry-wrapper {
    row-gap: 57px;
  }
}
@media screen and (max-width: 767px) {
  #root .bottom-section .bottom-container .description-container {
    padding: 0px 20px;
    max-width: unset;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    width: 100%;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container .left-container .inquiry-wrapper {
    margin-top: 10px;
    row-gap: 20px;
    margin-bottom: 56px;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container .left-container .inquiry-wrapper .text {
    width: 100%;
    font-size: 28px;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container .left-container .inquiry-wrapper .inquiry-btn {
    margin: 0 auto;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container .right-container .guide-container {
    -webkit-column-gap: 17px;
       -moz-column-gap: 17px;
            column-gap: 17px;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container .right-container .guide-container .box {
    width: 120px;
    height: 43px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 14px;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container .right-container .guide-container .description {
    font-size: 16px;
  }
  #root .bottom-section .bottom-container .description-container .service-center-container .right-container .guide-container .description > .mb {
    display: block;
  }
}