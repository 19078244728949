#root {
    .section1 {
        position: relative;
        width: 100%;
        height: 100%;
        height: 510px;

        &>.section1-wrapper {
            display: flex;
            align-items: center;
            margin: 0 auto;
            position: relative;
            z-index: 2;
            width: 1080px;
            height: 100%;

            &>.detail-container {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .main-text,
                .sub-text {
                    color: white;
                }

                &>.color-text {
                    color: white;
                    font-size: 18px;
                    font-weight: 800;
                }

                &>.main-text {
                    font-size: 33px;
                    font-weight: 800;
                    line-height: 47.6px;
                    white-space: pre-wrap;
                }

                &>.sub-text {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 28.8px;
                    white-space: pre-wrap;
                }


                &>.button {
                    width: 304px;
                    height: 50px;
                    background-color: white;
                    color: var(--main);
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                }
            }

        }

        .pos-type-background-img {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .inquiry {
        height: 310px;


    }
}

@media screen and (max-width:1439px) {
    #root {
        .section1 {
            &>.center-wrapper {
                justify-content: center;
            }

            .section1-wrapper {
                width: 680px;

            }
        }
    }
}

@media screen and (max-width:767px) {
    #root {

        .section1 {
            height: 400px;

            &>.center-wrapper {
                justify-content: center;
                display: flex;
                flex-direction: column;
                text-align: center;
                }

            .section1-wrapper {
                width: auto;
                padding: 0px 16px;



                .detail-container {
                    width: 100%;
                    &>.button {
                        margin:0 auto;
                    }
                    .main-text {
                        font-size: 26px;
                        line-height: 140%;
                    }

                    .sub-text {}
                }


            }
        }

    }
}