#root .inquiry-form-page {
  padding-top: 66px;
  padding-bottom: 250px;
}
#root .inquiry-form-page > .inquiry-title-section {
  width: 100%;
  height: 151px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--mint100);
}
#root .inquiry-form-page > .inquiry-title-section > .inquiry-title {
  font-size: 40px;
  font-weight: 700;
}
#root .inquiry-form-page > .inquiry-input-section {
  width: 100%;
  margin-top: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 42px;
     -moz-column-gap: 42px;
          column-gap: 42px;
}
#root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box {
  width: 477px;
  height: 456px;
  padding: 33px 34px;
  background-color: var(--mint700);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: white;
  row-gap: 8.49px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8.49px;
}
#root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box > .explanation-title {
  white-space: pre;
  font-size: 36px;
  font-weight: 700;
  line-height: 46.8px;
}
#root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box > .explanation-sub {
  white-space: pre;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}
#root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .img-box {
  width: 147px;
  height: 147px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  border-radius: 50%;
  margin-top: auto;
  margin-left: auto;
}
#root .inquiry-form-page > .inquiry-input-section > .input-wrapper {
  width: 506px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .inquiry-form-page > .inquiry-input-section > .input-wrapper > .agreement-check-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}
#root .inquiry-form-page > .inquiry-input-section > .input-wrapper > .agreement-check-wrapper > .submit-btn {
  width: 247px;
  height: 50px;
  color: white;
  margin-top: 8px;
  background-color: var(--mint800);
  font-weight: 700;
  border-radius: 5px;
}
#root .inquiry-form-page > .inquiry-input-section > .input-wrapper > .agreement-check-wrapper > .disable-btn {
  background-color: #DBDBDB;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .inquiry-form-page > .inquiry-input-section {
    width: 506px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
    margin: 0 auto;
    margin-top: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 24px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box {
    width: 100%;
    height: 220px;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
    padding: 24px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box > .explanation-title {
    font-size: 28px;
    line-height: 36.4px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box > .explanation-sub {
    font-size: 16px;
    line-height: 24px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .img-box {
    width: 120px;
    height: 120px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .img-box svg {
    width: 81.63px;
    height: 81.63px;
  }
}
@media screen and (max-width: 768px) {
  #root .inquiry-form-page > .inquiry-title-section {
    height: 120px;
  }
  #root .inquiry-form-page > .inquiry-title-section > .inquiry-title {
    font-size: 30px;
  }
  #root .inquiry-form-page > .inquiry-input-section {
    width: 100%;
    max-width: 508px;
    padding: 0px 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 25px;
    padding: 20px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box {
    width: 100%;
    height: 344px;
    padding: 24px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 16px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box > .explanation-title {
    font-size: 28px;
    line-height: 36.4px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .explanation-text-box > .explanation-sub {
    font-size: 16px;
    line-height: 24px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .img-box {
    width: 120px;
    height: 120px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .inquiry-explanation-box > .img-box svg {
    width: 81.63px;
    height: 81.63px;
  }
  #root .inquiry-form-page > .inquiry-input-section > .input-wrapper {
    width: 100%;
  }
  #root .inquiry-form-page > .inquiry-input-section > .input-wrapper > .agreement-check-wrapper > .submit-btn {
    width: 100%;
  }
}