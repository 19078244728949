#root .main-section11-card {
  width: 453px;
  height: 543px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: white;
  row-gap: 54px;
  padding-top: 50px;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .main-section11-card > .card-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  padding: 0px 50px;
}
#root .main-section11-card > .card-text > .text {
  font-size: 32px;
  font-weight: 700;
}
#root .main-section11-card > .card-text > .sub {
  white-space: pre;
  font-size: 24px;
  line-height: 36px;
  color: var(--grey500);
}
#root .main-section11-card img {
  -o-object-fit: fill;
     object-fit: fill;
  height: 301px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: auto;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section11-card {
    width: 335px;
    height: 404px;
    row-gap: 0;
  }
  #root .main-section11-card > .card-text {
    padding: 0px 22px;
  }
  #root .main-section11-card > .card-text > .text {
    font-size: 30px;
  }
  #root .main-section11-card > .card-text > .sub {
    font-size: 20px;
    line-height: 30px;
  }
  #root .main-section11-card img {
    height: 232px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section11-card {
    width: 328px;
    height: 348px;
    row-gap: 0;
    padding: 0;
  }
  #root .main-section11-card > .card-text {
    row-gap: 8px;
    padding: 20px;
  }
  #root .main-section11-card > .card-text > .text {
    font-size: 26px;
  }
  #root .main-section11-card > .card-text > .sub {
    font-size: 18px;
    line-height: 27px;
  }
  #root .main-section11-card img {
    height: 192px;
  }
}