#root .pc-bottom-trend-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  row-gap: 140px;
  margin-top: 10px;
}
#root .pc-bottom-trend-box .dot-line-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 45px;
}
#root .pc-bottom-trend-box .dot-line-box > .sm-circle {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: #01A0A0;
}
#root .pc-bottom-trend-box .dot-line-box > .dot-line {
  height: 63px;
  border: 1px dashed #01A0A0;
}
#root .pc-bottom-trend-box > .circle-wrapper {
  margin-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 35px;
     -moz-column-gap: 35px;
          column-gap: 35px;
}
#root .pc-bottom-trend-box > .circle-wrapper .circle-card:nth-child(3) {
  position: relative;
  top: 100px;
  z-index: 1;
}
#root .pc-bottom-trend-box > .circle-wrapper > .line {
  top: 40px;
  position: absolute;
  left: 260px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .pc-bottom-trend-box {
    top: -5px;
  }
  #root .pc-bottom-trend-box > .circle-wrapper > .line {
    top: 70px;
    left: 210px;
  }
}