#root .hover-inquiry {
  width: 144px;
  height: 96px;
  position: absolute;
  -webkit-box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, 0.1);
  right: -46px;
  top: 42px;
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  row-gap: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .hover-inquiry > .menu-text {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
}