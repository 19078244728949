#root .pc-alert-talk > .menu-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
#root .pc-alert-talk > .menu-wrapper > .line {
  width: 50%;
  position: absolute;
  bottom: 25px;
  border: 1px dashed #01A0A0;
}
#root .pc-alert-talk > .menu-wrapper > .menu-box {
  margin-top: 50px;
  z-index: 1;
  width: 174px;
  height: 50px;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #01A0A0;
  color: white;
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .pc-alert-talk > .menu-wrapper > .menu-box {
    margin-top: 56px;
  }
}
@media screen and (max-width: 767px) {
  #root .mb-alert-talk {
    row-gap: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .mb-alert-talk > .menu {
    margin-top: 50px;
    width: 174px;
    height: 50px;
    border-radius: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #01A0A0;
    color: white;
    font-size: 20px;
    font-weight: 700;
  }
}