#root .main-section12 {
  background-color: #F5F6F8;
  padding: 180px 0px;
}
#root .main-section12 > .main-section12-wrapper {
  width: 912px;
  margin: 0 auto;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section12 {
    padding-top: 152px;
    padding-bottom: 164px;
  }
  #root .main-section12 > .main-section12-wrapper {
    width: 688px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section12 {
    padding: 0;
    padding-top: 116px;
    padding-bottom: 72px;
  }
  #root .main-section12 > .main-section12-wrapper {
    width: 328px;
  }
}