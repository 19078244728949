#root .main-section10 {
  padding: 180px 0px;
  background-color: #363840;
}
#root .main-section10 > .wrapper {
  width: 1080px;
  margin: 0 auto;
}
#root .main-section10 > .wrapper > .text-wrapper > .text {
  font-size: 48px;
  font-weight: 700;
  color: white;
  text-align: center;
}
#root .main-section10 > .wrapper > .text-wrapper > .text > .color-text {
  color: #05D9AE;
}
#root .main-section10 > .wrapper > .content-wrapper {
  margin-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 182px;
}
#root .main-section10 > .wrapper > .btn-box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 60px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section10 {
    padding-top: 187px;
    padding-bottom: 162px;
  }
  #root .main-section10 > .wrapper {
    width: 652px;
  }
  #root .main-section10 > .wrapper > .text-wrapper > .text {
    font-size: 34px;
  }
  #root .main-section10 > .wrapper > .content-wrapper {
    margin-top: 88px;
    row-gap: 104px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section10 {
    padding-top: 135px;
    padding-bottom: 110px;
  }
  #root .main-section10 > .wrapper {
    width: 300px;
  }
  #root .main-section10 > .wrapper > .text-wrapper > .text {
    font-size: 30px;
  }
  #root .main-section10 > .wrapper > .content-wrapper {
    width: 100%;
    margin-top: 44px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 100px;
  }
}