#root .app-promotion {
  background-color: #F5F6F8;
  padding: 180px 0px;
}
#root .app-promotion > .wrapper {
  width: 912px;
  margin: 0 auto;
}
#root .app-promotion > .wrapper > .top-box .img-wrapper {
  margin-top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 48px;
     -moz-column-gap: 48px;
          column-gap: 48px;
}
#root .app-promotion > .wrapper > .top-box > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  text-align: center;
}
#root .app-promotion > .wrapper > .top-box > .text-wrapper > .text {
  font-size: 48px;
  font-weight: 700;
}
#root .app-promotion > .wrapper > .top-box > .text-wrapper > .sub-text {
  font-weight: 500;
  font-size: 24px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .app-promotion {
    padding-top: 152px;
    padding-bottom: 164px;
  }
  #root .app-promotion > .wrapper {
    width: 688px;
  }
  #root .app-promotion > .wrapper > .top-box > .text-wrapper > .text {
    font-size: 34px;
  }
  #root .app-promotion > .wrapper > .top-box > .text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .app-promotion > .wrapper > .top-box > .img-wrapper {
    margin-top: 39px;
    -webkit-column-gap: 39px;
       -moz-column-gap: 39px;
            column-gap: 39px;
  }
}
@media screen and (max-width: 767px) {
  #root .app-promotion {
    padding: 0;
    padding-top: 116px;
    padding-bottom: 72px;
  }
  #root .app-promotion > .wrapper {
    width: 328px;
  }
  #root .app-promotion > .wrapper > .top-box > .text-wrapper > .text {
    font-size: 30px;
  }
  #root .app-promotion > .wrapper > .top-box > .text-wrapper > .sub-text {
    font-size: 22ox;
    line-height: 33px;
    white-space: pre-line;
  }
}