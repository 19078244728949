#root .toggle-open-container {
  width: 100%;
  height: 100vh;
  background-color: white;
  position: absolute;
  z-index: 1500;
}
#root .toggle-open-container > .cancel-container {
  width: 28px;
  height: 28px;
  margin-left: auto;
  padding: 20px 15px 0px 0px;
}
#root .toggle-open-container > .cancel-container img {
  margin-left: auto;
}
#root .toggle-open-container > .mb-menulist-wrapper > .mb-menulist-container {
  margin-top: 43px;
  padding: 0px 20px;
}
#root .toggle-open-container > .mb-menulist-wrapper > .mb-menulist-container > .lists {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid var(--grey200);
  cursor: pointer;
}
#root .toggle-open-container > .mb-menulist-wrapper > .mb-menulist-container > .active-lists {
  color: var(--main);
}
#root .toggle-open-container > .mb-menulist-wrapper > .mb-menulist-container > .inquiry-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#root .toggle-open-container > .mb-menulist-wrapper > .mb-menulist-container > .inquiry-container.open {
  max-height: 500px;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
#root .toggle-open-container > .mb-menulist-wrapper > .mb-menulist-container > .inquiry-container.closed {
  max-height: 0;
  opacity: 0;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}