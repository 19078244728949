#root .my-inquiry-card {
  width: 100%;
  border-bottom: solid 1px #D9D9D9;
  overflow: hidden;
  max-height: 57px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .my-inquiry-card .inquiry-card-title-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 57px;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .my-inquiry-card .inquiry-card-title-section > .card-title {
  text-align: left;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .my-inquiry-card .inquiry-card-title-section .card-title-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px;
  font-weight: 400;
}
#root .my-inquiry-card .inquiry-card-title-section .card-title-right > .card-time {
  color: var(--grey500);
}
#root .my-inquiry-card .inquiry-card-title-section .card-title-right > .card-state {
  color: var(--grey400);
  margin: 0 28px 0 47px;
}
#root .my-inquiry-card .inquiry-card-title-section .card-title-right .answer-state {
  color: var(--mint800);
}
#root .my-inquiry-card .inquiry-card-content-section {
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--grey100);
  font-size: 15px;
  line-height: 160%;
  font-weight: 400;
  white-space: pre-line;
}
#root .my-inquiry-card .inquiry-card-content-section .answer-box {
  margin-top: 14px;
  background-color: white;
  padding: 22px 25px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .my-inquiry-card .inquiry-card-content-section .answer-box .answer-box-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}
#root .my-inquiry-card .inquiry-card-content-section .answer-box .answer-box-content {
  font-size: 12px;
  font-weight: 400;
  line-height: 200%;
}
#root .open-inquiry-card {
  max-height: 500px;
}
#root .open-inquiry-card .inquiry-card-title-section .card-title-right > .arrow-btn {
  -webkit-transform: scale(-1);
          transform: scale(-1);
}

@media screen and (max-width: 767px) {
  #root .my-inquiry-card .inquiry-card-title-section > .card-title > span {
    line-height: 190%;
    font-size: 12px;
    font-weight: 400;
    color: var(--grey500);
  }
  #root .my-inquiry-card .inquiry-card-title-section .card-title-right > .card-state {
    margin: 0 16px;
  }
}