#root .alert-talk-section7 {
  padding: 180px 0px;
  background-color: #24252C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .alert-talk-section7 > .text-wrapper {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 10px;
}
#root .alert-talk-section7 > .text-wrapper > .main-text {
  font-size: 48px;
  font-weight: 700;
}
#root .alert-talk-section7 > .text-wrapper > .sub-text {
  font-size: 24px;
}
#root .alert-talk-section7 > .coin {
  width: 364px;
  height: 364px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-section7 {
    padding: 160px 0px;
  }
  #root .alert-talk-section7 > .text-wrapper > .main-text {
    font-size: 34px;
  }
  #root .alert-talk-section7 > .text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .alert-talk-section7 > .coin {
    width: 330px;
    height: 330px;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-section7 {
    padding: 132px 0px;
  }
  #root .alert-talk-section7 > .text-wrapper > .main-text {
    font-size: 30px;
  }
  #root .alert-talk-section7 > .text-wrapper > .sub-text {
    font-size: 20px;
    white-space: pre-line;
    line-height: 30px;
    text-align: center;
  }
  #root > .coin {
    width: 328px;
    height: 328px;
  }
}