#root .login-popup {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .login-popup .login-wrapper {
  -webkit-animation: inside 0.2s ease-in;
          animation: inside 0.2s ease-in;
  width: 320px;
  max-height: 253px;
  background-color: white;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 0 20px;
  row-gap: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
#root .login-popup .login-wrapper > .cancel-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}
#root .login-popup .login-wrapper > .title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
#root .login-popup .login-wrapper > .social-wrapper {
  display: grid;
  width: 100%;
  row-gap: 8px;
  padding: 0 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .login-popup .login-wrapper > .social-wrapper > button {
  width: 100%;
  height: 44px;
  padding: 0 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
}
#root .login-popup .login-wrapper > .social-wrapper > button > img {
  width: 24px;
}
#root .login-popup .login-wrapper > .social-wrapper > button > b {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  font-size: 14px;
}
#root .login-popup .login-wrapper > .social-wrapper > button:nth-child(1) {
  background-color: #F9E000;
}
#root .login-popup .login-wrapper > .social-wrapper > button:nth-child(2) {
  background-color: #04C73C;
  color: white;
}
#root .login-popup .login-wrapper > .social-wrapper > button:nth-child(3) {
  background-color: #F4F4F4;
}
#root .login-popup .login-wrapper > .down-title {
  font-size: 18px;
  font-weight: 700;
}
#root .login-popup .login-wrapper > .down-sub {
  font-size: 13px;
  color: var(--grey500);
  line-height: 150%;
  font-weight: 400;
  text-align: center;
}
#root .login-popup .login-wrapper > .down-btn-wrapper {
  display: grid;
  grid-template-columns: 256px;
  grid-template-rows: repeat(2, 50px);
  row-gap: 8px;
}
#root .login-popup .login-wrapper > .down-btn-wrapper > button {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  color: var(--grey400);
}
#root .login-popup .login-wrapper > .down-btn-wrapper > button:nth-child(1) {
  background-color: var(--main);
  color: white;
}