#root .content-img {
  width: 100%;
  height: 464px;
  background-color: var(--mint800);
  border-radius: 10px;
  overflow: hidden;
  padding: 44px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .content-img > .text-wrapper {
  top: 44px;
  left: 44px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .content-img > .text-wrapper > .title {
  font-size: 34px;
  font-weight: 700;
  white-space: pre-wrap;
}
#root .content-img > .text-wrapper > .subtitle {
  font-size: 24px;
  white-space: pre-line;
  line-height: 150%;
  font-size: 500;
}
#root .content-img > img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .content-img {
    padding: 25px;
  }
  #root .content-img > .text-wrapper {
    top: 26px;
    left: 24px;
  }
  #root .content-img > .text-wrapper > .title {
    font-size: 30px;
    white-space: pre;
  }
  #root .content-img > .text-wrapper > .subtitle {
    white-space: pre;
    font-size: 20px;
  }
  #root .content-img > img {
    width: 180px;
  }
}
@media screen and (max-width: 767px) {
  #root .content-img {
    width: 328px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 24px;
    height: 328px;
  }
  #root .content-img > .text-wrapper > .title {
    font-size: 24px;
  }
  #root .content-img > .text-wrapper > .subtitle {
    font-size: 14px;
  }
  #root .content-img > img {
    width: 130px;
  }
}