#root .main-section9 {
  padding: 180px 0px;
  background-color: white;
}
#root .main-section9 > .wrapper {
  width: 1080px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
}
#root .main-section9 > .wrapper > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .main-section9 > .wrapper > .text-wrapper > .sub-text {
  font-size: 24px;
  font-weight: 500;
}
#root .main-section9 > .wrapper > .text-wrapper > .title {
  font-size: 48px;
  font-weight: 700;
}
#root .main-section9 > .wrapper > .img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .main-section9 > .wrapper > .img-wrapper > .img-box {
  width: 690px;
  height: 516px;
  overflow: hidden;
}
#root .main-section9 > .wrapper > .img-wrapper > .img-box > .slide {
  width: 690px;
  height: 516px;
  height: auto;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
#root .main-section9 > .wrapper > .img-wrapper > .img-box .slide.active {
  opacity: 1;
}
#root .main-section9 > .wrapper > .img-wrapper > .description {
  font-size: 36px;
  font-weight: 700;
  white-space: pre;
  line-height: 54px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section9 {
    padding-top: 183px;
    padding-bottom: 195px;
  }
  #root .main-section9 > .wrapper {
    width: 536px;
    row-gap: 32px;
  }
  #root .main-section9 > .wrapper > .text-wrapper {
    row-gap: 8px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .main-section9 > .wrapper > .text-wrapper > .title {
    font-size: 34px;
  }
  #root .main-section9 > .wrapper > .text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .main-section9 > .wrapper > .img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 36px;
  }
  #root .main-section9 > .wrapper > .img-wrapper > .img-box {
    width: 536px;
    height: 400px;
  }
  #root .main-section9 > .wrapper > .img-wrapper > .img-box > .slide {
    width: 536px;
    height: 400px;
  }
  #root .main-section9 > .wrapper > .img-wrapper > .description {
    font-size: 26px;
    text-align: center;
    line-height: 36.4px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section9 {
    padding-top: 110px;
    padding-bottom: 192px;
  }
  #root .main-section9 > .wrapper {
    width: 328px;
  }
  #root .main-section9 > .wrapper > .text-wrapper {
    row-gap: 8px;
    text-align: center;
  }
  #root .main-section9 > .wrapper > .text-wrapper > .title {
    font-size: 30px;
  }
  #root .main-section9 > .wrapper > .text-wrapper > .sub-text {
    font-size: 22px;
    line-height: 33px;
    white-space: pre;
  }
  #root .main-section9 > .wrapper > .img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 23px;
  }
  #root .main-section9 > .wrapper > .img-wrapper > .img-box {
    width: 328px;
    height: 244px;
  }
  #root .main-section9 > .wrapper > .img-wrapper > .img-box .slide {
    width: 328px;
  }
  #root .main-section9 > .wrapper > .img-wrapper > .description {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
}