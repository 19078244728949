#root .slider-content {
  overflow: hidden;
}
#root .slider-content .original {
  -webkit-animation: infiniteAnimation1 60s linear infinite normal none;
          animation: infiniteAnimation1 60s linear infinite normal none;
  -webkit-transition: 120s ease;
  transition: 120s ease;
}
#root .slider-content .clone {
  -webkit-animation: infiniteAnimation2 60s linear infinite;
          animation: infiniteAnimation2 60s linear infinite;
  -webkit-transition: 120s ease;
  transition: 120s ease;
}
#root .slider-content > .slider-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
}
#root .slider-content > .slider-wrapper > .slider-track {
  width: 100%;
  height: 522px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
  padding-right: 24px;
  margin-top: 50px;
}
#root .slider-content .company-img-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 190px;
  overflow: hidden;
}
#root .slider-content .company-img-wrapper .company-img {
  width: 100%;
  height: 82px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .slider-content .company-img-wrapper .company-img img {
  -o-object-fit: contain;
     object-fit: contain;
}
#root .slider-content .company-img-wrapper .company-img .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .slider-content .company-text-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .slider-content .company-text-wrapper .text-original {
  -webkit-animation: 150s linear infinite normal none running infiniteAnimation1;
          animation: 150s linear infinite normal none running infiniteAnimation1;
}
#root .slider-content .company-text-wrapper .text-clone {
  -webkit-animation: 150s linear infinite infiniteAnimation2;
          animation: 150s linear infinite infiniteAnimation2;
}
#root .slider-content .company-text-wrapper .text {
  white-space: nowrap;
  color: var(--grey500);
  font-size: 24px;
  font-weight: 600;
  white-space: pre;
}
@-webkit-keyframes infiniteAnimation1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50.1% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes infiniteAnimation1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50.1% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes infiniteAnimation2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}
@keyframes infiniteAnimation2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .slider-content > .slider-wrapper > .slider-track {
    height: 372px;
    margin-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  #root .slider-content > .slider-wrapper > .slider-track {
    height: 301px;
    margin-top: 10px;
  }
  #root .slider-content > .company-img-wrapper {
    margin-top: 100px;
    min-width: 100%;
  }
  #root .slider-content > .company-img-wrapper .company-img {
    width: 2926px;
  }
  #root .slider-content > .company-text-wrapper > .text {
    font-size: 19px;
  }
}