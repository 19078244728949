#root .download-page {
  padding-top: 66px;
}
#root .download-page > .good-choice-section > .good-choice-wrapper {
  padding-top: 131px;
  padding-bottom: 121px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 85px;
     -moz-column-gap: 85px;
          column-gap: 85px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 40px;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 5px;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .text-container > .color-text {
  color: var(--main);
  font-size: 18px;
  font-weight: 700;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .text-container > .text {
  white-space: pre-wrap;
  font-size: 34px;
  font-weight: 800;
  line-height: 140%;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .text-container > .sub-text {
  font-weight: 400;
  white-space: pre-wrap;
  line-height: 180%;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 13px;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .card-container > .card {
  width: 250px;
  height: 106px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 23px;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .card-container > .card > img {
  width: 45px;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .card-container > .card > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 5px;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .card-container > .card > .title-container > .title {
  color: var(--main);
  font-size: 20px;
  font-weight: 700;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .containers > .card-container > .card > .title-container > .subtitle {
  font-size: 13px;
  font-weight: 400;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .img-container {
  position: relative;
}
#root .download-page > .good-choice-section > .good-choice-wrapper > .img-container > .pos-img {
  width: 485px;
  height: 442px;
  position: relative;
  z-index: 100;
}
#root .download-page > .as-section {
  background-color: #F5F6F8;
}
#root .download-page > .as-section > .as-wrapper {
  padding-top: 127px;
  padding-bottom: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 80px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .download-page > .as-section > .as-wrapper > .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 4px;
}
#root .download-page > .as-section > .as-wrapper > .text-container > .color-text {
  font-size: 18px;
  font-weight: 700;
  color: var(--main);
}
#root .download-page > .as-section > .as-wrapper > .text-container > .text {
  font-size: 34px;
  font-weight: 800;
  white-space: pre-wrap;
  line-height: 47.6px;
  text-align: center;
}
#root .download-page > .as-section > .as-wrapper > .card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .download-page > .as-section > .as-wrapper > .card-container > .arrow {
  width: 47px;
}
#root .download-page > .as-section > .as-wrapper > .card-container > .card {
  width: 246px;
  height: 135px;
  background-color: white;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  padding-top: 52px;
}
#root .download-page > .as-section > .as-wrapper > .card-container > .card > .title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
}
#root .download-page > .as-section > .as-wrapper > .card-container > .card > .circle {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background-color: var(--main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: -50px;
}
#root .download-page > .as-section > .as-wrapper > .card-container > .card > .circle > .asimg {
  width: 43px;
  height: 42px;
}
#root .download-page > .as-section > .as-wrapper > .card-container .card:nth-child(1) .title br:nth-child(1) {
  display: none;
}
#root .download-page > .as-section > .as-wrapper > .bubble {
  position: relative;
  width: 507px;
  height: 125px;
  padding: 0px;
  background: var(--grey600);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 28px;
     -moz-column-gap: 28px;
          column-gap: 28px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .download-page > .as-section > .as-wrapper > .bubble svg {
  width: 50px;
  height: 36px;
}
#root .download-page > .as-section > .as-wrapper > .bubble > .detail-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: 30px;
}
#root .download-page > .as-section > .as-wrapper > .bubble > .detail-text .color-text {
  color: var(--main);
  font-size: 20px;
  font-weight: 700;
}
#root .download-page > .as-section > .as-wrapper > .bubble > .detail-text .text {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
#root .download-page > .as-section > .as-wrapper > .bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: var(--grey600) transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 245px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper {
  padding-top: 210px;
  padding-bottom: 148px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 40px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 8px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .text-container > .color-text {
  color: var(--mint800);
  font-size: 18px;
  font-weight: 700;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .text-container > .text {
  font-size: 34px;
  font-weight: 800;
  line-height: 47.6px;
  white-space: pre-wrap;
  text-align: center;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 26px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container > .card {
  width: 885px;
  height: 292px;
  border-radius: 5px;
  background-color: var(--grey100);
  padding-left: 50px;
  padding-right: 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 60px;
     -moz-column-gap: 60px;
          column-gap: 60px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container > .card img {
  width: 231px;
  height: 231px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container > .card > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container > .card > .title-container hr {
  width: 439px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container > .card > .title-container > .color-title {
  font-size: 24px;
  font-weight: 700;
  color: var(--mint800);
  padding-bottom: 10px;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container > .card > .title-container > .sub-title {
  font-size: 18px;
  font-weight: 700;
}
#root .download-page > .pos-service-section > .pos-service-wrapper > .card-container > .card > .title-container > .lists {
  line-height: 39px;
  white-space: pre-wrap;
}

@media screen and (max-width: 1439px) {
  #root .download-page .good-choice-section .good-choice-wrapper .containers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .text-container {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    text-align: center;
    z-index: 500;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .card-container {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .img-container {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .img-container .pos-img {
    width: 485px;
    height: 442px;
    position: relative;
    z-index: 100;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .img-container .cloud-img {
    width: 369px;
    height: 369px;
    position: absolute;
    right: -70px;
    top: -160px;
  }
  #root .download-page .as-section .as-wrapper {
    padding: 127px 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .download-page .as-section .as-wrapper .card-container .card {
    width: 198px;
    height: 135px;
  }
  #root .download-page .as-section .as-wrapper .card-container .card:nth-child(1) .title br:nth-child(1) {
    display: block;
  }
  #root .download-page .as-section .as-wrapper .card-container .card:nth-child(1) .title br:nth-child(2) {
    display: none;
  }
  #root .download-page .pos-service-section .pos-service-wrapper {
    padding: 134px 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card {
    width: auto;
    height: 292px;
    padding-left: 14px;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card .title-container hr {
    width: 329.999px;
  }
}
@media screen and (max-width: 767px) {
  #root .download-page .good-choice-section .good-choice-wrapper {
    padding: 60px 16px;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers {
    row-gap: 0px;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .text-container .text {
    font-size: 26px;
    font-weight: 800;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .card-container .card {
    width: 160px;
    height: 155px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    row-gap: 14px;
    padding: 0 8px;
  }
  #root .download-page .good-choice-section .good-choice-wrapper .containers .img-container .pos-img {
    width: 245px;
    top: -34px;
    left: 20px;
    height: 224px;
  }
  #root .download-page .as-section .as-wrapper {
    padding: 60px 16px;
    row-gap: 40px;
  }
  #root .download-page .as-section .as-wrapper .text-container .text {
    line-height: 30px;
    font-size: 26px;
    white-space: pre-wrap;
  }
  #root .download-page .as-section .as-wrapper .card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 20px;
  }
  #root .download-page .as-section .as-wrapper .card-container .arrow {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
  }
  #root .download-page .as-section .as-wrapper .card-container .card {
    margin-top: 40px;
  }
  #root .download-page .as-section .as-wrapper .bubble {
    width: 328px;
    height: 130px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    row-gap: 12px;
  }
  #root .download-page .as-section .as-wrapper .bubble .detail-text {
    line-height: 20px;
  }
  #root .download-page .as-section .as-wrapper .bubble .detail-text .color-text {
    font-size: 16px;
    font-weight: 700;
  }
  #root .download-page .as-section .as-wrapper .bubble .detail-text .text {
    font-size: 13px;
  }
  #root .download-page .as-section .as-wrapper > .bubble:after {
    content: "";
    left: 154px;
  }
  #root .download-page .pos-service-section .pos-service-wrapper {
    padding: 66px 16px;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .text-container {
    width: 100%;
    white-space: pre-wrap;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .text-container > .text {
    font-size: 26px;
    line-height: 140%;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card {
    width: auto;
    height: 517px;
    padding: 0px 21px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    row-gap: 20px;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card svg {
    width: 36.744px;
    height: 28.558px;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card .title-container .color-title,
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card .title-container .sub-title {
    text-align: center;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card .title-container hr {
    width: 285px;
  }
  #root .download-page .pos-service-section .pos-service-wrapper .card-container .card .lists .list {
    width: 250px;
    margin: 0 auto;
  }
}