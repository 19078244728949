#root .footer-wrapper {
  background-color: var(--grey800);
  color: white;
}
#root .footer-wrapper > .footer-container {
  padding: 63px 58px;
}
#root .footer-wrapper > .footer-container > .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .footer-wrapper > .footer-container > .container > span {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
#root .footer-wrapper > .footer-container > .container > .footer-logo {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 16px;
}
#root .footer-wrapper > .footer-container > .container > .footer-text {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 16px;
  line-height: 160%;
}
#root .footer-wrapper > .footer-container > .container > .privacy {
  font-size: 13px;
  margin-top: 12px;
  color: white;
}
#root .footer-wrapper > .footer-container > .container > .description-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .footer-wrapper > .footer-container > .container > .description-container > .contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .footer-wrapper > .footer-container > .container > .description-container > .contact img {
  width: 16px;
  height: 16px;
}
#root .footer-wrapper > .footer-container > .container > .description-container > .contact p {
  font-size: 13px;
  font-weight: 400;
}
#root .footer-wrapper > .footer-container > .container > .description-container > .white-contact svg path {
  fill: white;
}
#root .footer-wrapper > .footer-container > .container > .reserved {
  padding-top: 50px;
}

@media screen and (max-width: 767px) {
  #root .footer-wrapper .footer-container {
    padding: 64px 15px;
  }
  #root .footer-wrapper .footer-container .container .description-container .contact svg {
    position: relative;
    top: 5px;
  }
  #root .footer-wrapper .footer-container .container .description-container .contact p {
    width: 272px;
    line-height: 23px;
  }
}