#root .agreement-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 16px;
  background-color: #F5F6F8;
}
#root .agreement-check > .check-box-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .agreement-check > .check-box-wrapper > .check-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  border: 1px solid #949494;
}
#root .agreement-check > .check-box-wrapper > .active-check-box {
  border: 1px solid var(--mint800);
}
#root .agreement-check > .check-box-wrapper > .text {
  font-weight: 400;
  color: #54585A;
}
#root .agreement-check > .show-link {
  font-size: 12px;
  color: #949494;
  text-decoration: underline;
  white-space: nowrap;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .agreement-check > .check-box-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .agreement-check > .check-box-wrapper > .text {
    font-size: 12px;
  }
  #root .agreement-check > .show-link {
    font-size: 11px;
  }
}
@media screen and (max-width: 768px) {
  #root .agreement-check {
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
  }
  #root .agreement-check > .check-box-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .agreement-check > .check-box-wrapper > .check-box {
    width: 15px;
    height: 15px;
  }
  #root .agreement-check > .check-box-wrapper > .text {
    font-size: 10px;
  }
  #root .agreement-check > .show-link {
    font-size: 10px;
  }
}