#root .alert-talk-section4 {
  width: 100%;
  padding: 180px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #01A0A0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 50px;
}
#root .alert-talk-section4 > .alert-talk-section4-text-wrapper {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 10px;
}
#root .alert-talk-section4 > .alert-talk-section4-text-wrapper > .main-text {
  font-size: 48px;
  font-weight: 700;
}
#root .alert-talk-section4 > .alert-talk-section4-text-wrapper > .sub-text {
  font-size: 24px;
  font-weight: 500;
}
#root .alert-talk-section4 > .img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
#root .alert-talk-section4 > .img-wrapper > .img-box {
  width: 401px;
  height: 499px;
  padding: 16px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
}
#root .alert-talk-section4 > .img-wrapper > .img-box img {
  -o-object-fit: cover;
     object-fit: cover;
}
#root .alert-talk-section4 > .img-wrapper > .img-box > .img-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  padding: 0px 14px;
}
#root .alert-talk-section4 > .img-wrapper > .img-box > .img-text-wrapper > .img-main-text {
  font-size: 32px;
  font-weight: 700;
}
#root .alert-talk-section4 > .img-wrapper > .img-box > .img-text-wrapper > .img-sub-text {
  font-size: 24px;
  white-space: pre;
  line-height: 36px;
  color: #54585A;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .alert-talk-section4 > .alert-talk-section4-text-wrapper > .main-text {
    font-size: 34px;
  }
  #root .alert-talk-section4 > .alert-talk-section4-text-wrapper > .sub-text {
    font-size: 24px;
  }
  #root .alert-talk-section4 > .img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 44px;
  }
}
@media screen and (max-width: 767px) {
  #root .alert-talk-section4 {
    padding: 132px 0px;
  }
  #root .alert-talk-section4 > .alert-talk-section4-text-wrapper > .main-text {
    font-size: 30px;
  }
  #root .alert-talk-section4 > .alert-talk-section4-text-wrapper > .sub-text {
    font-size: 20px;
  }
  #root .alert-talk-section4 > .img-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 40px;
  }
  #root .alert-talk-section4 > .img-wrapper > .img-box {
    width: 328px;
    height: 433px;
    padding: 14px 14px;
  }
}