#root .main-section4 {
  padding-top: 112px;
  padding-bottom: 210px;
  background-color: #F5F6F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 50px;
}
#root .main-section4 > .title {
  white-space: pre;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
  text-align: center;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section4 {
    row-gap: 0;
    padding-top: 127px;
    padding-bottom: 145px;
  }
  #root .main-section4 > .title {
    font-size: 34px;
    line-height: 44.2px;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section4 {
    padding: 102px 0px;
    row-gap: 0px;
  }
  #root .main-section4 > .title {
    font-size: 32px;
    line-height: 42px;
  }
}