#root .pc-middle-trend-box {
  margin-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 90px;
     -moz-column-gap: 90px;
          column-gap: 90px;
}
#root .pc-middle-trend-box > .bubble-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}
#root .pc-middle-trend-box > .skpos-logo-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .pc-middle-trend-box > .skpos-logo-wrapper > .top-line {
  position: absolute;
  top: -52px;
}
#root .pc-middle-trend-box > .skpos-logo-wrapper > .bottom-line {
  bottom: -61px;
  right: -63px;
  position: absolute;
}
#root .pc-middle-trend-box > .skpos-logo-wrapper > .skpos-logo {
  width: 170px;
  height: 170px;
  background-color: white;
  -webkit-box-shadow: 0 0 20px 20px rgba(5, 217, 174, 0.3490196078);
          box-shadow: 0 0 20px 20px rgba(5, 217, 174, 0.3490196078);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .pc-middle-trend-box {
    margin-top: 130px;
    -webkit-column-gap: 70px;
       -moz-column-gap: 70px;
            column-gap: 70px;
  }
  #root .pc-middle-trend-box > .skpos-logo-wrapper > .top-line {
    width: 210px;
    top: -35px;
  }
  #root .pc-middle-trend-box > .skpos-logo-wrapper > .bottom-line {
    width: 210px;
    bottom: -35px;
    right: -42px;
  }
  #root .pc-middle-trend-box > .skpos-logo-wrapper > .skpos-logo {
    width: 132px;
    height: 132px;
    -webkit-box-shadow: 0 0 15px 15px rgba(5, 217, 174, 0.3490196078);
            box-shadow: 0 0 15px 15px rgba(5, 217, 174, 0.3490196078);
  }
  #root .pc-middle-trend-box > .skpos-logo-wrapper > .skpos-logo img {
    width: 85px;
  }
}