#root {
    .main-section2 {
        background-color: white;
        margin-top: 15px;
        padding: 50px 0px 180px;

        &>.content-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            text-align: center;
            &>.content-title-wrapper {
                display: flex;
                flex-direction: column;
                row-gap: 13px;
                color: var(--grey600);

                &>.content-title {
                    font-size: 48px;
                    font-weight: 700;
                }

                &>.content-sub-title {
                    font-size: 24px;
                    font-weight: 500;
                }
            }

            &>.satisfaction-wrapper {
                display: flex;
                height: 142px;
                justify-content: center;

                &>.satisfaction-card:nth-child(3) {
                    text-align: center;

                    &>.satisfactio-sub {
                        font-size: 34px;
                        border-right: none;
                    }
                }

                &>.satisfaction-card {
                    padding: 0px 32px;
                    border-right: 2px solid #DBDBDB;
                    display: flex;
                    flex-direction: column;
                    row-gap: 14px;
                    color: var(--grey600);

                    &>.satisfaction-title {
                        font-size: 24px;
                        font-weight: 500;
                        margin-top: 5px;
                    }

                    &>.satisfactio-sub {
                        font-size: 48px;
                        white-space: pre;
                        font-weight: 600;
                        line-height: 130%;
                    }

                }

                &>.satisfaction-card:nth-child(3) {
                    border: unset;
                }
            }
        }
    }
}

@media screen and (max-width:1439px) and (min-width:769px) {
    #root {
        .main-section2 {

            &>.content-wrapper {
                row-gap: 46px;

                &>.content-title-wrapper {
                    &>.content-title {
                        font-size: 40px;
                    }

                    &>.content-sub-title {
                        font-size: 24px;
                    }
                }

                &>.satisfaction-wrapper {
                    &>.satisfaction-card {
                        &>.satisfaction-title {
                            font-size: 24px;
                        }

                        &>.satisfactio-sub {
                            font-size: 48px;
                        }
                    }
                }
            }

        }
    }
}

@media screen and (max-width:768px) {
    #root {
        .main-section2 {
            padding: 30px 0px 130px;

            &>.content-wrapper {
                position: relative;
                row-gap: 20px;

                &>.content-title-wrapper {
                    &>.content-title {
                        font-size: 40px;
                    }

                    &>.content-sub-title {
                        font-size: 22px;
                    }
                }

                &>.satisfaction-wrapper {
                    width: 100%;
                    overflow: hidden;
                    position: relative;

                    &>.satisfaction-card {
                        position: absolute;
                        opacity: 0;
                        border-right: none;
                        transition: opacity 1s ease-in-out;

                        &>.satisfaction-title {
                            font-size: 20px;
                        }

                        &>.satisfaction-sub {
                            font-size: 48px;
                        }
                    }

                    &>.active {
                        opacity: 1;
                    }
                }
            }

        }
    }
}