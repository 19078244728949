#root .header-wrapper {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: white;
}
#root .header-wrapper .auth-container {
  float: right;
  margin-left: auto;
}
#root .header-wrapper .auth-container > .auth-box {
  background-color: black;
  width: 90px;
  height: 32px;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .header-wrapper .auth-container > .auth-box button {
  color: white;
  font-weight: 700;
}
#root .header-wrapper .auth-container > .logout-box {
  background-color: unset;
}
#root .header-wrapper .auth-container > .logout-box > button {
  color: var(--grey500);
}
#root .header-wrapper > .header-container {
  padding: 21px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 66px;
}
#root .header-wrapper > .header-container > .logo-img-container {
  width: 134px;
}
#root .header-wrapper > .header-container > .header-nav {
  padding-left: 111px;
}
#root .header-wrapper > .header-container > .header-nav > .header-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 33px;
     -moz-column-gap: 33px;
          column-gap: 33px;
}
#root .header-wrapper > .header-container > .header-nav > .header-ul > .header-menu {
  font-size: 16px;
  font-weight: 700;
}
#root .header-wrapper > .header-container > .header-nav > .header-ul > .header-menu:nth-child(5) {
  position: relative;
  z-index: 1;
}
#root .header-wrapper > .header-container > .header-nav > .header-ul > .header-menu:nth-child(5) > .header-menu-text {
  width: 90px;
  cursor: pointer;
}
#root .header-wrapper > .header-container > .header-nav > .header-ul > .header-menu:nth-child(5):hover > .header-menu-text {
  position: absolute;
  height: 140px;
}
#root .header-wrapper > .mb-header {
  position: relative;
}
#root .header-wrapper > .mb-header > .mb-header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 14px 13px 14px 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .header-wrapper > .mb-header > .mb-header-container > .logo-img-container {
  width: 115.008px;
  font-weight: bold;
}
#root .header-wrapper > .mb-header > .mb-header-container .mb-header-right-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
}