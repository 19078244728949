#root .trend-top-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 125px;
  position: relative;
  bottom: -35px;
}
#root .trend-top-box > .dot-line-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 46px;
}
#root .trend-top-box > .dot-line-box > .sm-circle {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: #01A0A0;
}
#root .trend-top-box > .dot-line-box > .dot-line {
  height: 63px;
  border: 1px dashed #01A0A0;
}
#root .trend-top-box .top-dot-line {
  top: 354px;
}
#root .trend-top-box .top-dot-line > .sm-circle {
  background-color: #05D9AE;
  bottom: 0;
}
#root .trend-top-box > .circle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  top: 4px;
  -webkit-column-gap: 35px;
     -moz-column-gap: 35px;
          column-gap: 35px;
}
#root .trend-top-box > .circle-wrapper .circle-card:nth-child(3) {
  position: relative;
  top: -100px;
  z-index: 1;
}
#root .trend-top-box > .circle-wrapper > .line {
  position: absolute;
  left: 120px;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .trend-top-box {
    bottom: -50px;
  }
  #root .trend-top-box > .circle-wrapper > .line {
    top: -50px;
    left: 70px;
  }
  #root .trend-top-box > .dot-line-box {
    top: 236px;
  }
  #root .trend-top-box > .dot-line-box > .dot-line {
    height: 60px;
  }
}
@media screen and (max-width: 768px) {
  #root .trend-top-box {
    row-gap: 72px;
  }
  #root .trend-top-box > .circle-wrapper {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
  #root .trend-top-box > .circle-wrapper .circle-card:nth-child(3) {
    top: -52px;
  }
  #root .trend-top-box > .circle-wrapper > .line {
    top: -10px;
    left: 55px;
  }
  #root .trend-top-box > .dot-line-box {
    top: 200px;
  }
  #root .trend-top-box > .dot-line-box > .dot-line {
    height: 80px;
  }
}