#root .open-license {
  height: 100%;
  padding: 180px 0px;
}
#root .open-license > .open-license-wrapper {
  width: 1075px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 45px;
}
#root .open-license > .open-license-wrapper > .license-title {
  white-space: pre;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
}
#root .open-license > .open-license-wrapper > .selected-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 18px;
     -moz-column-gap: 18px;
          column-gap: 18px;
}
#root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}
#root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .color-btn {
  background-color: #01A0A0;
}
#root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .color-btn svg:nth-child(1) path {
  fill: white;
}
#root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .color-btn > .arrow path {
  stroke: white;
}
#root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .color-btn > .text-box {
  color: white;
}
#root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .color-btn > .text-box > .title, #root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .color-btn > .text-box > .sub {
  color: white;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .open-license {
    padding-top: 120px;
    padding-bottom: 128px;
  }
  #root .open-license > .open-license-wrapper {
    width: 688px;
  }
  #root .open-license > .open-license-wrapper > .license-title {
    font-size: 34px;
    line-height: 44.2px;
  }
}
@media screen and (max-width: 767px) {
  #root .open-license > .open-license-wrapper > .title-wrapper {
    font-size: 30px;
  }
  #root .open-license > .open-license-wrapper > .selected-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper {
    gap: 8px;
    width: 328px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
  }
  #root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .btn {
    width: 152px;
    height: 60px;
    padding-left: 0px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .open-license > .open-license-wrapper > .selected-wrapper > .btn-wrapper > .btn span {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  #root .open-license {
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 60px 0;
  }
  #root .open-license > .open-license-wrapper {
    width: 328px;
    row-gap: 16px;
  }
  #root .open-license > .open-license-wrapper > .license-title {
    font-size: 30px;
    line-height: 42px;
  }
}