#root .main-section6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 125px;
     -moz-column-gap: 125px;
          column-gap: 125px;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 180px 0px;
}
#root .main-section6 > .gradient {
  width: 100%;
  position: absolute;
  top: 0;
}
#root .main-section6 > .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  z-index: 1;
}
#root .main-section6 > .text-wrapper > .main-text {
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
  white-space: pre;
}
#root .main-section6 > .text-wrapper > .sub-text {
  font-size: 24px;
  font-weight: 500;
  white-space: pre;
  color: #363840;
  line-height: 36px;
}
#root .main-section6 > .img-wrapper {
  z-index: 1;
}

@media screen and (max-width: 1439px) and (min-width: 769px) {
  #root .main-section6 {
    padding-top: 104px;
    padding-bottom: 116px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 58px;
  }
  #root .main-section6 > .text-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .main-section6 > .text-wrapper > .main-text {
    font-size: 34px;
    line-height: 44.2px;
    text-align: center;
  }
  #root .main-section6 > .text-wrapper > .sub-text {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  #root .main-section6 {
    padding-top: 104px;
    padding-bottom: 111px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 46px;
  }
  #root .main-section6 > .text-wrapper > .main-text {
    font-size: 30px;
    line-height: 41px;
    text-align: center;
  }
  #root .main-section6 > .text-wrapper > .sub-text {
    text-align: center;
    font-size: 20px;
  }
}